@import "mixins";
@import "buttons";
@import "variables";

body {
  margin: 0;
  padding: 0;
  font-family: $nunito;
}

button:is(:focus, :active) {
  outline: none !important;
  box-shadow: unset !important;
}

.mb-20 {
  margin-bottom: 16px;

  @include for-size(desk-1440) {
    margin-bottom: 14px !important;
  }
}

.top-radius {
  border-radius: 5px 5px 0 0;
}

.bottom-radius {
  border-radius: 0 0 5px 5px;
}

.radius-5 {
  border-radius: 5px;
}

.card-shadow {
  box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.1);
}

.p-16 {
  padding: 16px;

  @include for-size(desk-1440) {
    padding: 14px;
  }
}

.p-20 {
  padding: 20px;

  @include for-size(desk-1440) {
    padding: 14px;
  }
}

.mx-n3 {
  margin-right: -16px;
  margin-left: -16px;
}

.login-wrapper {
  background-image: url("../images/login-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100dvh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000065;
  }

  .logo-block {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 10;

    @include for-size(ipad-850) {
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      @include for-size(ipad-850) {
        height: 34px;
      }
    }
  }

  .copyright-block {
    position: absolute;
    bottom: 40px;
    left: 40px;
    z-index: 10;

    @include for-size(ipad-850) {
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    p {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
      margin: 0;

      @include for-size(ipad-850) {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .login-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 40px;

    @include for-size(ipad-850) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
    }

    .login-content {
      position: relative;
      z-index: 10;
      width: min(100% - 20px, 640px);

      @include for-size(desk-1440) {
        width: min(100% - 20px, 540px);
      }

      @include for-size(desk-1199) {
        width: min(100% - 20px, 440px);
      }

      @include for-size(ipad-992) {
        width: min(100% - 20px, 330px);
        text-align: center;
      }

      @include for-size(ipad-850) {
        width: 100%;
      }

      h1 {
        position: relative;
        display: inline-block;
        color: #fff;
        font-size: 44px;
        font-weight: 800;
        line-height: 54px;
        margin-bottom: 30px;
        padding-left: 24px;

        @include for-size(desk-1440) {
          font-size: 34px;
          line-height: 44px;
        }

        @include for-size(desk-1199) {
          font-size: 28px;
          line-height: 38px;
          padding-left: 18px;
          margin-bottom: 22px;
        }

        @include for-size(ipad-992) {
          font-size: 28px;
          line-height: 32px;
          padding-left: 0;
        }

        @include for-size(phone-576) {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 18px;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          width: 5px;
          background-color: $primary-color;
          height: 100%;
          top: 0;

          @include for-size(ipad-992) {
            display: none;
          }
        }
      }

      p {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 20px;

          @include for-size(desk-1199) {
            margin-bottom: 16px;
          }
        }

        @include for-size(desk-1199) {
          font-size: 14px;
          line-height: 20px;
        }

        @include for-size(ipad-850) {
          display: none;
        }
      }
    }

    .login-form {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      background: #ffffff;
      border-radius: 10px;
      padding: 20px;
      width: 460px;
      position: relative;
      z-index: 10;

      @include for-size(desk-1440) {
        padding: 20px;
        width: 400px;
      }

      @include for-size(desk-1199) {
        width: 400px;
      }

      @include for-size(ipad-850) {
        padding: 16px;
        width: min(100% - 0px, 460px);
      }

      .form-title {
        span {
          position: relative;
          display: inline-block;
          font-size: 34px;
          font-weight: 700;
          color: $text-black;
          text-transform: capitalize;
          margin-bottom: 35px;

          @include for-size(desk-1440) {
            font-size: 34px;
            margin-bottom: 30px;
          }

          @include for-size(ipad-850) {
            font-size: 24px;
            margin-bottom: 24px;
          }

          @include for-size(phone-576) {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 18px;
          }

          &::after {
            content: "";
            height: 2px;
            width: 60px;
            background-color: #2666fa;
            display: block;
            transform: translateX(-50%);
            left: 50%;
            position: absolute;
            bottom: -10px;

            @include for-size(ipad-850) {
              bottom: -10px;
            }
          }
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: #7a7a7a;
          margin: 0 0 20px;

          @include for-size(desk-1440) {
            line-height: 19px;
          }
        }
      }

      label {
        @extend %label;
      }

      form {
        label {
          @extend %label;
        }

        .form-block {
          margin-bottom: 16px;
          // display: flex;
          flex-direction: column-reverse;
          width: 100%;

          @include for-size(ipad-850) {
            margin-bottom: 16px;
          }

          label {
            @extend %label;
          }

          input {
            @extend %default-form;
            transition: all 300ms ease;

            &:focus {
              background-color: #f2f8ff;
              border: 1px solid $primary-color;
              transition: all 300ms ease;
            }
          }

          .pwd-form {
            position: relative;

            .eye-icon {
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
            }

            input[type="text"]:focus ~ label,
            input[type="email"]:focus ~ label,
            input[type="password"]:focus + label {
              color: $primary-color !important;
            }
          }

          &.client-code-field {
            input[type="text"] {
              text-transform: uppercase;
              &::placeholder {
                text-transform: none;
              }
            }
          }

          input[type="text"]:focus ~ label,
          input[type="email"]:focus ~ label,
          input[type="password"]:focus ~ label {
            color: $primary-color !important;
          }
        }

        .remember-me-block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 16px;
        }

        a {
          display: inline-block;
          color: $text-black;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
          text-decoration: none;
          transition: all 300ms ease-in;

          &:hover {
            color: $primary-color;
            transition: all 300ms ease-in;
          }
        }

        .checkbox-outline-primary span {
          color: $text-black;
          font-size: 14px;
          font-weight: 400;
        }

        .checkbox-outline-primary .checkmark {
          width: 20px;
          height: 20px;
        }

        .sign-btn {
          display: block;
          color: #fff;
          background-color: $primary-color;
          border-radius: 5px;
          border: 1px solid $primary-color;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
          text-transform: capitalize;
          padding: 10px 0;
          margin: 20px 0 20px;
          width: 100%;
          transition: all 0.3s ease-in;

          @include for-size(desk-1440) {
            margin: 16px 0 16px;
          }

          @include for-size(ipad-850) {
            margin: 16px 0;
          }

          &:hover {
            background-color: #0065d2;
            color: #fff;
            outline: none;
            border: 1px solid #0065d2;
            transition: all 0.3s ease-in;
          }

          &:focus {
            background-color: #0065d2;
            color: #fff;
            outline: none;
            border: 1px solid #0065d2;
            transition: all 0.3s ease-in;
          }
        }

        ::placeholder {
          color: $placeholder;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #f2f8ff !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.custom-breadcrumb {
  margin: 0 0 16px;

  @include for-size(tablet-768) {
    margin: 0 0 16px;
  }

  nav {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        display: inline-flex;
        align-items: center;
        position: relative;

        a {
          font-size: 14px;
          font-weight: 400;
          color: #7a7a7a;
          text-transform: capitalize;
          text-decoration: none;

          &.active {
            color: $text-black;
          }
          &:hover {
            color: $text-black;
          }
        }

        &:not(:last-child) {
          &::after {
            content: "/";
            color: #7a7a7a;
            font-size: 14px;
            margin: 0 6px;
          }
        }

        &:last-child {
          a {
            color: #333;
          }
        }
      }
    }
  }
}

.dashboard-card-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  @include for-size(desk-1280) {
    flex-wrap: wrap;
  }

  @include for-size(tablet-768) {
    gap: 16px;
  }

  .dashboard-card {
    background: #fff;
    box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.1);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    flex: 1;
    position: relative;

    @include for-size(desk-1440) {
      padding: 14px;
      flex: 1 0 auto;
    }

    @include for-size(desk-1280) {
      flex: 1 1 47%;
    }

    @include for-size(tablet-768) {
      padding: 16px;
    }

    @include for-size(phone-600) {
      flex: 1 1 100%;
    }

    .dashboard-card-icon {
      width: 70px;
      height: 70px;
      background-color: #007aff15;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      @include for-size(desk-1440) {
        width: 56px;
        height: 56px;
      }

      @include for-size(tablet-768) {
        width: 50px;
        height: 50px;
      }

      img {
        @include for-size(desk-1440) {
          height: 24px;
        }
      }

      &.active-project {
        background-color: #04bf5b;

        + .dashboard-card-text {
          h4 {
            color: #04bf5b;
          }
        }
      }

      &.active-round {
        background-color: #ff9948;

        + .dashboard-card-text {
          h4 {
            color: #ff9948;
          }
        }
      }

      &.online-field {
        background-color: #00bbd4;

        + .dashboard-card-text {
          h4 {
            color: #00bbd4;
          }
        }
      }

      &.active-survey {
        background-color: #5069aa;

        + .dashboard-card-text {
          h4 {
            color: #5069aa;
          }
        }
      }
    }

    .dashboard-card-text {
      h4 {
        color: $text-black;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 0;

        @include for-size(desk-1440) {
          font-size: 26px;
        }

        @include for-size(tablet-768) {
          font-size: 18px;
        }
      }

      h6 {
        color: $text-black;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0;

        @include for-size(desk-1440) {
          font-size: 18px;
        }

        @include for-size(tablet-768) {
          font-size: 18px;
        }
      }

      p {
        color: $text-black;
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        white-space: nowrap;

        @include for-size(tablet-768) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        @include for-size(desk-1440) {
          margin-top: 4px;
        }

        li {
          display: inline-flex;
          align-items: center;

          span,
          p {
            font-size: 16px;
            font-weight: 400;

            @include for-size(desk-1440) {
              font-size: 14px;
            }
          }

          span {
            color: $text-gray;
          }

          p {
            color: $text-black;
          }

          &:not(:last-child) {
            &::after {
              content: "|";
              color: $text-gray;
              margin: 0 12px;
              font-size: 14px;

              @include for-size(phone-454) {
                visibility: hidden;
              }
            }
          }
        }
      }

      .applicant-btn {
        @extend %button-primary;

        @include for-size(desk-1440) {
          padding: 7px 14px;
          font-size: 13px;
          line-height: 19px;
        }
      }

      &.button-added {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .applicant-btn {
          @include for-size(tablet-768) {
            font-size: 0;
            width: 34px;
            height: 34px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .applicant-btn-dropdown {
        &::after {
          background-image: url("../images/form-drop-arrow.svg");
          width: 12px;
          height: 12px;
          background-repeat: no-repeat;
          background-position: center;
          border: none;
        }
      }

      .dropdown-center {
        position: relative;
        display: inline-block;
        margin-left: 6px;

        .applicant-dropdown-menu {
          width: 170px;
          border: 1px solid $border-color;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);
          top: 10px !important;
          left: -27px !important;
          padding: 0;
          overflow-y: auto;
          height: 248px;

          .applicant-list {
            &:not(:last-child) {
              border-bottom: 1px solid #e0e0e0;
            }

            a {
              margin: 12px;
              text-decoration: none;
              display: block;

              h6 {
                color: $text-black;
                font-size: 14px;
                font-weight: 700;
              }

              p {
                font-weight: 400;
                font-size: 12px;
                color: $text-gray;
                margin-bottom: 0;
              }

              &:hover {
                h6 {
                  color: $primary-color;
                }
              }
            }
          }

          &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 9px solid #d9d9d9;
            position: absolute;
            right: 47px;
            top: -9px;
            rotate: 180deg;

            // @include for-size(ipad-991) {
            //     right: 5px;
            //     left: auto;
            // }
          }

          &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 8px solid #fff;
            position: absolute;
            right: 48px;
            top: -8px;
            rotate: 180deg;

            // @include for-size(ipad-991) {
            //     right: 6px;
            //     left: auto;
            // }
          }
        }
      }

      &.applicant-card-text {
        @include for-size(phone-600) {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 14px;
        }
      }
    }

    &.applicant-card {
      @include for-size(phone-600) {
        align-items: flex-start;
      }
    }

    .dashboard-light-icon {
      position: absolute;
      right: 0;
      bottom: 0;

      img {
        @include for-size(desk-1440) {
          width: 40px;
        }
      }
    }

    .site-drawer-btn {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      @extend %button-outline-icon;

      @include for-size(desk-1440) {
        width: 24px;
        height: 24px;
      }

      svg {
        @include for-size(desk-1440) {
          height: 8px;
        }
      }
    }
  }

  &.active-data {
    flex-direction: column;
    // width: min(100% - 20px, 480px);

    .dashboard-card {
      width: 100%;
      padding: 16px;

      @include for-size(desk-1440) {
        padding: 22px;
      }

      @include for-size(ipad-1024) {
        flex: 1;
      }

      @include for-size(tablet-768) {
        padding: 16px;
      }

      @include for-size(phone-600) {
        flex: 1 1 100%;
      }
    }

    @include for-size(ipad-1024) {
      flex-direction: row;
      width: 100%;
    }
  }

  .dashboard-card-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;

    @include for-size(phone-576) {
      flex-direction: column;
    }

    a {
      flex: 1;

      @include for-size(phone-576) {
        width: 100%;
      }
    }
  }

  .tab-badge {
    font-size: 10px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 3px 8px;
    border-radius: 3px;
    display: inline-block;
    white-space: nowrap;

    &.completed {
      color: #3bb537;
      background-color: #3bb53724;
      border: 1px solid #3bb537;
    }

    &.not-approved {
      color: #ee4549;
      background-color: #ee45491a;
      border: 1px solid #ee4549;
    }

    &.pending {
      color: #6c7688;
      background-color: #6c76881a;
      border: 1px solid #6c7688;
    }

    &.hold {
      color: #a32499;
      background-color: #a324991a;
      border: 1px solid #a32499;
    }
  }
}

.active-data-graph-wrapper {
  margin-bottom: 20px;

  @include for-size(desk-1440) {
    margin-bottom: 14px;
  }

  @include for-size(ipad-1024) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include for-size(tablet-768) {
    margin-bottom: 16px;
    gap: 16px;
  }

  .custom-card {
    @extend %card-body;
    flex: 1;

    .custom-card-title {
      padding: 16px;
      border-bottom: 1px solid $border-color;

      @include for-size(desk-1440) {
        padding: 14px;
      }

      h5 {
        @extend %title;

        @include for-size(desk-1440) {
          font-size: 18px;
        }
      }

      @include for-size(tablet-768) {
        padding: 16px;
      }

      &:has(.card-header-button) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .card-header-button {
        .edit-btn {
          @extend %button-primary;
          padding: 6px 16px;

          @include for-size(desk-1440) {
            padding: 7px 14px;
            font-size: 13px;
            line-height: 19px;
          }
        }
      }
    }

    .custom-card-body {
      padding: 16px;

      @include for-size(desk-1440) {
        padding: 14px;
      }

      .survey-graph {
        height: 258px;
        object-fit: contain;
        width: 100%;

        @include for-size(desk-1440) {
          height: 215px;
        }

        @include for-size(ipad-1024) {
          height: auto;
        }
      }

      .project-data-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 16px;

        .project-data {
          flex-basis: 23%;

          @include for-size(tablet-768) {
            flex-basis: 30%;
          }

          @include for-size(phone-600) {
            flex-basis: 48%;
          }

          @include for-size(phone-576) {
            flex-basis: 100%;
          }

          small {
            font-size: 12px;
            font-weight: 400;
            color: $text-mute;
            margin-bottom: 4px;
            display: inline-block;
          }

          h6 {
            font-size: 16px;
            font-weight: 400;
            color: $text-black;
            margin-bottom: 0;
            line-height: 21px;
          }
        }
      }

      hr {
        opacity: 1;
        color: $border-color;
      }
    }
  }

  &.card-height {
    height: 100%;

    .custom-card {
      height: calc(100% - 15px);
    }
  }
}

/* ************************* 
sites side panel design start
****************************/
.site-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:not(:first-child) {
    padding-top: 16px;

    @include for-size(desk-1440) {
      padding-top: 14px;
    }
  }

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid $border-color;

    @include for-size(desk-1440) {
      padding-bottom: 14px;
    }
  }

  .site-wrapper-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    flex: 0 1 65%;

    .site-user-img {
      width: 60px;
      height: 60px;
      flex-shrink: 0;

      @include for-size(desk-1440) {
        width: 46px;
        height: 46px;
      }

      img {
        border-radius: 5px;
        max-width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid $border-color;
      }

      @include for-size(desk-1199) {
        width: 40px;
        height: 40px;
      }
    }

    .site-user-name {
      font-weight: 700;
      font-size: 28px;
      background-color: #007aff0d;
      color: $primary-color;
      border-radius: 5px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $primary-color;
      text-transform: uppercase;
      flex-shrink: 0;

      @include for-size(desk-1440) {
        width: 46px;
        height: 46px;
        font-size: 24px;
      }
    }

    .site-canvas-content {
      small {
        font-weight: 600;
        font-size: 12px;
        color: #7a7a7a;
        margin-bottom: 0;
        display: block;
      }

      h6 {
        @extend %sub-title;
        text-transform: unset;

        @include for-size(tablet-768) {
          font-size: 14px;
        }
      }

      ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;

        li {
          display: inline-flex;
          align-items: center;
          position: relative;

          p {
            font-weight: 400;
            font-size: 14px;
            color: $text-black;
            margin: 0;

            span {
              font-weight: 700;
              font-size: 14px;
              color: $primary-color;
            }
          }

          &:not(:last-child) {
            &::after {
              // content: "|";
              content: "";
              color: #333;
              background-color: #333;
              width: 1px;
              height: 11px;
              margin: 0 7px;
            }
          }
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: $text-black;
        margin-bottom: 0;
      }
    }

    &.chain-wrapper-content {
      flex-grow: 1;

      .site-user-img {
        width: 40px;
        height: 40px;
      }

      .site-user-name {
        width: 40px;
        height: 40px;
      }

      a {
        font-weight: 600;
        font-size: 16px;
        text-decoration: none;
        display: inline-block;
        text-transform: capitalize;
      }
    }
  }

  .site-canvas-button-block {
    .add-project-btn {
      @extend %button-fill-icon;
      margin: 0 auto 6px;

      @include for-size(desk-1440) {
        width: 24px;
        height: 24px;
      }
    }

    p {
      font-weight: 600;
      font-size: 12px;
      color: #333;
      margin: 0;
      text-transform: capitalize;
    }
  }
}

/* ************************ 
sites side panel design end
**************************/

/* ************************* 
field-wrapper deisgn start
****************************/
.field-wrapper {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  > span {
    @extend %label;
    display: inline-block;

    @include for-size(desk-1440) {
      margin-bottom: 8px;
      font-size: 13px;
    }
  }

  .field-rep-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;

    .field-radio-block {
      border: 1px solid #e0e0e0;
      height: 40px;
      border-radius: 5px;
      padding: 12px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 86px;

      @include for-size(desk-1440) {
        height: 36px;
      }

      label,
      .checkbox {
        span {
          text-transform: capitalize;
          color: $text-black;
          line-height: 20px;
        }
      }
    }

    &.camera-rep-block {
      input {
        &:checked {
          ~ .checkmark {
            border: 1px solid #3bb537;

            &::after {
              background: #3bb537;
            }
          }
        }
      }
    }
  }
}

/* ************************* 
field-wrapper deisgn end
****************************/

/* ************************************ 
assigned survey side panel design start
***************************************/
.canvas-body-container {
  margin-top: 0;

  .assigned-survey-list {
    // padding-bottom: 65px;
    padding-bottom: 0;
    .assign-survey-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid $border-color;

      @include for-size(desk-1440) {
        padding: 14px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      .survey-checkbox {
        display: inline-flex;
        gap: 10px;
        align-items: flex-start;
        flex: 1 1 80%;
        word-wrap: break-word;

        .checkbox {
          width: 18px;
          height: 18px;
          margin-bottom: 0;
        }

        > p {
          @extend %label;
          margin-bottom: 0;
          width: 350px;

          @include for-size(phone-520) {
            width: 250px;
            line-height: 20px;
          }
        }

        .survey-canvas-name {
          p {
            @extend %label;
            margin-bottom: 0;
            width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;

            @include for-size(phone-520) {
              width: 250px;
              line-height: 20px;
            }
          }

          span {
            @extend %label;
            color: $text-gray;
          }
        }
      }

      button {
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
      }
    }

    .fieldrep-surevy-checkbox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
    }

    .user-name-badge {
      span {
        font-weight: 700;
        font-size: 14px;
        background-color: #007aff0d;
        color: $primary-color;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $primary-color;
        margin: 0 auto;
      }
    }

    .survey-fieldrep-data {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;

      .fieldrep-content {
        small {
          font-size: 12px;
          font-weight: 600;
          color: $text-gray;
          display: inline-block;
          margin-bottom: 0;
        }

        h6 {
          @extend %sub-title;
          text-transform: capitalize;
        }

        p {
          @extend %content;

          &:last-child {
            color: $text-gray;
          }

          span {
            color: $text-black;
            font-size: inherit;
          }
        }
      }

      .emp-badge {
        background-color: #ff9948;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #fff;
        position: absolute;
        bottom: -2px;
        right: -2px;

        img {
          width: 8px;
          height: 8px;
        }

        &.employee {
          background-color: #00bbd4 !important;
        }
      }
    }
  }
}

.survey-search {
  background-color: #f2f8ff;
  padding: 10px 16px;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;

  // @include for-size(desk-1440) {
  //   top: 55px;
  // }

  .assign-survey-name {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .survey-checkbox {
      display: inline-flex;
      gap: 10px;
      align-items: flex-start;
      flex: 1 1 80%;

      .checkbox {
        width: 18px;
        height: 18px;
        margin-bottom: 0;
      }

      .uncheckbox {
        margin: 0;
      }

      p {
        @extend %label;
        margin-bottom: 0;
        font-weight: 700;
      }
    }

    .search-survey-form {
      @extend %default-form;
      height: 34px;
      width: 300px;
      line-height: 34px;
    }

    .delete-btn {
      @extend %button-fill-icon;
      background-color: $text-danger;
      width: 34px;
      height: 34px;

      @include for-size(desk-1440) {
        width: 30px;
        height: 30px;
      }

      svg {
        margin-right: 0;
        width: 14px;
        height: 16px;

        @include for-size(desk-1440) {
          width: 14px;
          height: 14px;
        }
      }

      &:hover,
      &:focus {
        border-color: $text-danger;

        svg,
        path {
          fill: $text-danger;
        }
      }
    }

    ::placeholder {
      color: $placeholder;
      font-weight: 400;
      font-size: 14px;
    }

    &.setting-canvas-header {
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search-survey-form {
        width: calc(100% - 90px);
      }

      .setting-add-btn {
        @extend %button-primary;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .table-tooltip {
      background: #fff;
      border: 1px solid #d9d9d9 !important;
      border-radius: 5px;
      max-width: 310px;
      width: 310px;
      position: absolute;
      z-index: 10;
      top: 67px;
      right: 0;
      left: -14px;
      padding: 14px;
      display: none;
      box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 9px solid #d9d9d9;
        position: absolute;
        left: 19px;
        top: -9px;
        rotate: 180deg;
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 8px solid #fff;
        position: absolute;
        left: 20px;
        top: -8px;
        rotate: 180deg;
      }

      > p {
        font-size: 12px;
        font-weight: 400;
        color: $text-black;
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }
}

.selected-survey {
  background-color: #007aff14;
}

.assigned-delete-survey {
  margin-top: 0;
  position: sticky;
  top: 54px;
}

.assigned-margin {
  margin-top: 0;
}

/* ************************************ 
assigned survey side panel design end
***************************************/

.canvas-form {
  .canvas-form-wrapper {
    padding: 16px 16px 0;

    .form-block {
      margin-bottom: 16px;
      width: 100%;

      @include for-size(desk-1440) {
        margin-bottom: 14px;
      }

      label {
        @extend %label;

        @include for-size(desk-1440) {
          margin-bottom: 8px;
          font-size: 13px;
        }
      }

      input,
      textarea {
        @extend %default-form;
        transition: all 300ms ease;

        &:focus {
          background-color: #f2f8ff;
          border: 1px solid $primary-color;
          transition: all 300ms ease;
        }

        @include for-size(desk-1440) {
          height: 36px;
          line-height: 36px;
          padding: 0 13px;
        }
      }

      .nice-select-form {
        height: 40px;
        line-height: 40px;
        padding: 0 15px;

        @include for-size(desk-1440) {
          height: 36px;
          line-height: 36px;
          padding: 0 13px;
        }
      }

      .form-select {
        background-image: url("../images/form-drop-arrow.svg");
        background-size: 11px;
        background-position: right 15px center;
      }

      textarea {
        padding: 10px 15px;
        resize: unset;

        @include for-size(desk-1440) {
          padding: 0 13px;
        }
      }

      small {
        @extend %error;
      }

      ::placeholder {
        color: $placeholder;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .form-seperator {
    padding: 8px 16px;
    background-color: rgba(0, 122, 255, 0.05);

    span {
      font-size: 14px;
      font-weight: 600;
      color: $primary-color;
      text-transform: capitalize;
      display: inline-block;
    }
  }

  .card-form-footer {
    border-top: 1px solid $border-color;
    padding: 16px;
    position: absolute;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    left: 0;

    @include for-size(desk-1440) {
      padding: 14px;
    }

    .submit-btn {
      @extend %button-primary;
      margin-right: 16px;

      @include for-size(desk-1440) {
        padding: 7px 14px;
        font-size: 13px;
        line-height: 19px;
      }
    }

    .cancel-btn {
      @extend %button-transparent-outline;

      @include for-size(desk-1440) {
        padding: 7px 14px;
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
}

.modal-content {
  p {
    @extend %content;
    color: $text-black;
    margin-bottom: 20px;

    span {
      font-size: inherit;
      color: $text-black;
    }
  }

  .modal-form {
    .form-block {
      margin-bottom: 16px;
      width: 100%;

      @include for-size(desk-1440) {
        margin-bottom: 14px;
      }

      label {
        @extend %label;

        @include for-size(desk-1440) {
          margin-bottom: 8px;
          font-size: 13px;
        }
      }

      input,
      textarea {
        @extend %default-form;
        transition: all 300ms ease;

        &:focus {
          background-color: #f2f8ff;
          border: 1px solid $primary-color;
          transition: all 300ms ease;
        }

        @include for-size(desk-1440) {
          height: 40px;
          line-height: 22px;
          padding: 0 13px;
        }
      }

      .nice-select-form {
        height: 40px;
        line-height: 40px;
        padding: 0 15px;

        @include for-size(desk-1440) {
          height: 40px;
          line-height: 40px;
          padding: 0 13px;
        }
      }

      .pwd-form {
        position: relative;

        .eye-icon {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .form-select {
        background-image: url("../images/form-drop-arrow.svg");
        background-size: 11px;
        background-position: right 15px center;
      }

      small {
        @extend %error;
      }

      ::placeholder {
        color: $placeholder;
        font-weight: 400;
        font-size: 14px;
      }

      .form-block-icon {
        position: relative;

        .form-icon {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
        }

        input {
          text-indent: 20px;
        }
      }
    }
    .image-upload {
      position: relative;
      display: inline-block;

      .profile-pic {
        width: 120px;
        height: 120px;
        object-fit: cover;
      }

      button {
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        background-color: #33333370;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
      }

      > img {
        border-radius: 5px;
        border: 1px solid $border-color;
      }
    }

    .modal-button-block {
      .submit-btn {
        @extend %button-primary;
        margin-right: 16px;

        @include for-size(desk-1440) {
          padding: 7px 14px;
          font-size: 13px;
          line-height: 19px;
        }
      }

      .cancel-btn {
        @extend %button-transparent-outline;

        @include for-size(desk-1440) {
          padding: 7px 14px;
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }
}

.drag-form-block {
  padding: 16px 16px 20px;
  background-color: #f7fbff;
  border-radius: 5px;
  margin-bottom: 16px;
  border: 1px dashed #e0e0e0;
  position: relative;

  .question-number {
    color: $primary-color;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
  }

  .question-header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    // align-items: center;

    textarea {
      width: 100%;
      border: 0;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.2;
      color: $text-black;
      resize: unset;
      overflow: hidden;
      background: transparent;
      &::placeholder {
        color: $text-gray;
      }
    }

    p {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0;
      width: calc(100% - 65px);
      color: $text-gray;
      display: flex;
      gap: 5px;
    }

    .question-text {
      outline: none;
      &::after {
        content: attr(data-placeholder);
        color: #9b9b9b;
        position: absolute;
        top: 15px;
        left: 35px;
        user-select: none;
        text-indent: 0;
        font-size: 15px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        cursor: text;
      }
    }

    .question-header-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      visibility: hidden;

      .delete-btn {
        @extend %button-fill-icon;
        background-color: $text-danger;
        width: 18px;
        height: 18px;
        padding: 0;

        svg,
        path {
          margin-right: 0;
          width: 8px;
          height: 8px;
          fill: #fff;
        }

        &:hover,
        &:focus {
          border-color: $text-danger;

          svg,
          path {
            fill: $text-danger;
          }
        }
      }

      .add-btn {
        @extend %button-fill-icon;
        background-color: $primary-color;
        width: 18px;
        height: 18px;
        padding: 0;

        svg,
        path {
          margin-right: 0;
          width: 10px;
          height: 10px;
          fill: #fff;
        }

        &:hover,
        &:focus {
          border-color: $primary-color;

          svg,
          path {
            fill: $primary-color;
          }
        }
      }
    }
  }

  .form-builder-control {
    @extend %default-form;
    transition: all 300ms ease;

    @include for-size(desk-1440) {
      height: 36px;
      line-height: 36px;
      padding: 0 13px;
    }

    &:focus {
      background-color: #f2f8ff;
      border: 1px solid $primary-color;
      transition: all 300ms ease;
    }
  }

  .field-rep-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-top: 16px;
    flex-wrap: wrap;

    .field-radio-block {
      padding: 5px 10px;
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: 3px;

      .checkbox {
        > span {
          color: $primary-black;
          line-height: 20px;
        }
      }

      .checkmark {
        width: 16px;
        height: 16px;
        top: 2px;

        &::after {
          height: 9px;
          width: 5px;
        }
      }

      .checkbox-outline-primary {
        padding-left: 23px;
      }

      textarea {
        padding: 10px 15px;
      }

      .nice-select-form {
        height: 32px;
        line-height: 32px;
        padding: 0 15px;
        font-size: 14px;

        .current {
          display: inline-block;
          margin-right: 24px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }

  .form-select {
    background-image: url("../images/form-drop-arrow.svg");
    background-size: 11px;
    background-position: right 15px center;
  }

  .question-title {
    display: inline-flex;
    align-items: center;
    background-color: $primary-color;
    padding: 2px 10px;
    border-radius: 3px;
    position: absolute;
    right: 16px;
    bottom: -12px;
    visibility: hidden;

    svg,
    path {
      fill: #fff;
      height: 12px;
      width: 12px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      display: inline-block;
      margin-left: 6px;
      text-transform: capitalize;
    }
  }

  &.header-block {
    .question-header {
      margin-bottom: 0;
    }
    textarea {
      &::placeholder {
        font-size: 20px;
      }
    }
  }

  &.single-checkbox-block {
    .question-number {
      vertical-align: super;
    }
  }

  &:hover {
    border: 1px dashed $primary-color;
    cursor: move;

    .question-header {
      p {
        color: $text-black;
      }

      .question-header-buttons {
        visibility: visible;
      }
    }

    .question-title {
      visibility: visible;
    }
  }
}

.options-block {
  background: rgba(0, 122, 255, 0.05);
  border: 1px dashed #e0e0e0;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include for-size(desk-1440) {
    padding: 14px;
  }

  @include for-size(tablet-767) {
    gap: 6px;
    align-items: flex-start;
  }

  .label-form {
    @extend %default-form;
    transition: all 300ms ease;

    &:focus {
      background-color: #f2f8ff;
      border: 1px solid $primary-color;
      transition: all 300ms ease;
    }

    @include for-size(desk-1440) {
      height: 36px;
      line-height: 36px;
      padding: 0 13px;
    }
  }

  .options-checkbox {
    display: inline-flex;
    align-items: flex-start;
    gap: 10px;

    .checkbox {
      .checkmark {
        top: -2px;
      }
    }
  }

  .label-value-field-wrapper {
    flex-basis: 92%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;

    @include for-size(tablet-767) {
      flex-direction: column;
      align-items: stretch;
    }

    .delete-btn {
      @extend %button-fill-icon;
      background-color: $text-danger;
      flex-shrink: 0;
      width: 22px;
      height: 22px;

      svg {
        margin-right: 0;
      }

      &:hover,
      &:focus {
        border-color: $text-danger;
        background-color: $text-danger;

        svg,
        path {
          fill: $text-danger;
        }
      }
    }

    .add-project-btn {
      background-color: $primary-color;
      flex-shrink: 0;
      width: 22px;
      height: 22px;
      padding: 0;

      svg {
        margin-right: 0;
      }

      &:hover,
      &:focus {
        border-color: $primary-color;
        background-color: $primary-color;

        svg,
        path {
          fill: $primary-color;
        }
      }
    }

    .label-field,
    .value-field {
      flex: 1 1 auto;

      @include for-size(tablet-767) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }
    }

    label {
      @extend %label;

      @include for-size(tablet-767) {
        margin-bottom: 8px;
        line-height: 12px;
      }
    }

    .value-field {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      gap: 16px;

      @include for-size(tablet-767) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
      }
    }

    .label-field {
      input {
        @include for-size(tablet-767) {
          width: calc(100% - 38px);
        }
      }
    }
  }

  ::placeholder {
    color: $placeholder;
    font-weight: 400;
    font-size: 14px;
  }
}

/* ***************************
email template design start 
*************************** */
.email-modal-content {
  border: 1px solid $border-color;
  border-radius: 5px;

  h6 {
    @extend %title;
    text-align: center;
    margin: 20px 0;

    @include for-size(desk-1440) {
      margin: 14px 0;
    }
  }

  p {
    @extend %content;
    color: #333;
    line-height: 21px;

    &:not(:last-child) {
      margin-bottom: 15px;

      @include for-size(desk-1440) {
        margin-bottom: 14px;
      }
    }

    strong {
      font-weight: 700;
      font-size: inherit;
    }
  }

  .thanks-lines {
    margin-top: 20px;

    @include for-size(desk-1440) {
      margin-top: 16px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .email-modal-logo {
    background-color: #f7fbff;
    border-radius: 5px 5px 0 0;
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid $border-color;

    @include for-size(desk-1440) {
      padding: 14px;
    }

    img {
      @include for-size(desk-1440) {
        width: 110px;
      }
    }
  }
}

.email-accordion {
  box-shadow: none;

  .email-accordion-item {
    margin-bottom: 0 !important;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    .accordion-header {
      padding: 12px 40px;
      background-color: #f2f8ff;
      justify-content: flex-start;
      align-items: center;

      @include for-size(tablet-768) {
        padding: 16px;
        // flex-direction: column-reverse;
        // align-items: flex-start;
        gap: 10px;
      }

      .accordion-button {
        p {
          font-weight: 700;
          font-size: 14px;
          color: $text-black;
          margin-bottom: 0;

          @include for-size(tablet-768) {
            width: 80%;
          }
        }

        width: 100%;

        @include for-size(tablet-768) {
          width: 100%;
        }

        &::after {
          left: -31px;
          right: auto;
          rotate: -90deg;

          @include for-size(tablet-768) {
            right: -3px;
            left: auto;
            width: 12px;
            height: 12px;
            top: calc(50% - 6px);
          }
        }
      }

      .add-email-btn {
        @extend %button-fill-icon;
        width: auto;
        height: auto;
        padding: 1px 6px;
        border-radius: 5px;
        font-size: 12px;
        margin-left: 15px;
        visibility: hidden;

        @include for-size(tablet-768) {
          visibility: visible;
        }

        svg,
        path {
          width: 10px;
          height: 10px;
          margin-right: 3px;

          @include for-size(tablet-768) {
            margin: 0;
            width: 10px;
            height: 10px;
          }
        }

        @include for-size(tablet-768) {
          margin-left: 0;
          font-size: 0;
          width: 20px;
          height: 20px;
          padding: 0;
          flex-shrink: 0;
          position: absolute;
          right: 16px;
        }
      }

      &:hover {
        .add-email-btn {
          visibility: visible;
        }
      }
    }

    .accordion-collapse {
      .accordion-body {
        .accordion-email-content {
          padding: 10px 20px 10px 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include for-size(tablet-768) {
            padding: 16px;
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 12px;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $border-color;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: $text-black;
            margin-bottom: 0;

            @include for-size(tablet-768) {
              width: 80%;
            }
          }

          .email-content-action {
            display: inline-flex;
            gap: 16px;
            visibility: hidden;

            @include for-size(tablet-768) {
              visibility: visible;
            }
          }

          &.accordion-action {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          &:hover {
            p {
              color: $primary-color;
            }

            .email-content-action {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .accordion-button:not(.collapsed)::after {
    rotate: 180deg !important;
  }

  .column-btn {
    @extend %button-primary-outline;
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    padding: 0;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 0;
    }

    &:hover,
    &:focus {
      svg,
      path {
        fill: #fff;
      }
    }

    &::after {
      display: none;
    }
  }

  .dropdown-center {
    position: relative;

    .dropdown-menu {
      width: 170px;
      border: 1px solid $border-color;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);
      top: 10px !important;
      // left: -27px !important;
      transform: translateX(-145px) translateY(15px) !important;
      padding: 10px;

      h6 {
        color: $text-black;
        font-size: 14px;
        font-weight: 700;
        padding: 12px;
        border-bottom: 1px solid $border-color;
      }

      .checkbox-group {
        padding: 12px;

        span {
          font-size: 14px;
          color: $text-black;
        }

        label {
          &:not(:last-child) {
            margin-bottom: 12px;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  .badge-outline {
    @extend %badge-sm-outline;
    display: inline-block;
    line-height: 16px;
    font-size: 12px;
    margin-right: 4px;
    white-space: nowrap;
    cursor: pointer;
  }

  .no-button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
    outline: 0;
  }

  .token-badge {
    border-radius: 3px;
    background-color: $primary-color;
    padding: 3px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 12px;
      color: #fff;
      text-transform: capitalize;
      margin-right: 6px;
    }
  }
}

.email-preview-modal {
  table {
    width: 100% !important;
  }
}

/* ***********************
email template design end 
**************************/

.permission-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 20px;
  padding: 0px 20px 0 0;
  width: 100%;

  .permission-category-title {
    padding: 15px 20px;
    background-color: #f7fbff;
    flex-shrink: 0;
    width: 175px;

    h5 {
      @extend %sub-title;
      text-transform: capitalize;
      line-height: 21px;

      @include for-size(desk-1440) {
        font-size: 14px;
      }
    }

    // &:not(:last-child) {
    //     border-bottom: 1px solid #e0e0e0;
    // }

    // &:first-child {
    //     border-top: 1px solid #e0e0e0;
    // }
  }

  @include for-size(desk-1440) {
    width: 100%;
  }
  @include for-size(ipad-992) {
    flex-direction: column;
    padding-right: 0;
    .permission-category-title {
      width: 100%;
    }
    .permission-checkbox-grp {
      align-self: flex-start;
      width: 100%;
      padding: 15px;
      gap: 15px;
      .permission-checkbox-list {
        gap: 12px;
        .form-media {
          width: max-content;
        }
      }
    }
  }
  @include for-size(phone-575) {
    .permission-checkbox-grp {
      flex-direction: column;
    }
  }

  .form-media {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 16px;
    width: 170px;

    h6 {
      font-size: 14px;
      font-weight: 400;
      color: $text-black;
      margin-bottom: 3px;

      @include for-size(desk-1440) {
        font-size: 13px;
      }
    }

    small {
      font-size: 12px;
      font-weight: 400;
      color: $placeholder;
    }

    .form-media {
      width: 18px;
      height: 18px;
      display: inline-block;
    }

    label {
      display: inline;
      width: 18px;
      height: 18px;
      font-size: 14px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }

  &:first-child {
    border-top: 1px solid #e0e0e0;
  }

  .checkmark {
    top: 0;
  }
}

.all-none-btn {
  display: inline-flex;
  align-items: center;

  button {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: $text-black;
    position: relative;

    &:hover,
    &:focus {
      color: $primary-color;
      box-shadow: none;
      border: none;
      outline: none;
    }

    &.active {
      color: $primary-color;
    }

    &:not(:last-child) {
      &::after {
        content: "|";
        color: #e0e0e0;
        margin: 0 10px;
      }
    }
  }
}

.permissions-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow-x: auto;
  width: 100%;
  flex-wrap: nowrap;
}

.permission-category-list {
  flex-shrink: 0;
  width: 175px;

  flex-shrink: 0;
  width: 175px;
  position: sticky;
  left: 0;
  z-index: 10;

  @include for-size(desk-1440) {
    width: auto;
  }
}

.permission-checkbox-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.permissions-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 1000px;
  flex-direction: column;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;

  @include for-size(desk-1440) {
    width: auto;
  }
}

.permission-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.permission-checkbox .form-media.checkbox-outline-primary .checkmark {
  top: 0;
}

.permission-title {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 10px solid #fafafa;

  @include for-size(desk-1440) {
    padding: 14px;
  }

  h5 {
    @extend %title;

    @include for-size(desk-1440) {
      font-size: 18px;
    }
  }

  .form-media {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-bottom: 16px;
    width: 170px;

    h6 {
      font-size: 14px;
      font-weight: 400;
      color: $text-black;
      margin-bottom: 3px;
    }

    small {
      font-size: 12px;
      font-weight: 400;
      color: $placeholder;
    }

    .form-media {
      width: 18px;
      height: 18px;
      display: inline-block;
    }

    label {
      display: inline;
      width: 18px;
      height: 18px;
      @include for-size(phone-576) {
        font-size: 14px;
      }
    }

    .checkmark {
      top: 0;
    }
  }
}

.setting-canvas-btn {
  padding: 16px 20px;
  border-top: 1px solid #e0e0e0;

  @include for-size(desk-1440) {
    padding: 14px 16px;
  }

  // &:not(:last-child) {
  //     border-top: 1px solid #e0e0e0;
  // }

  // &:last-child {
  //     border-bottom: none;
  // }

  // &:first-child {
  //     border-top: 1px solid #e0e0e0 !important;
  // }

  button {
    font-size: 14px;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

/* *******************************
Questions template design start 
******************************* */
.survey-template-body {
  background-color: #f3f3f3;
}

.survey-questions {
  .survey-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-size(desk-1366) {
      padding: 20px;
    }

    .survey-title {
      h5 {
        @extend %title;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: $text-black;
        margin: 6px 0 0;
        line-height: 21px;
        display: inline-block;

        strong {
          font-size: inherit;
          font-weight: 700;
        }
      }
    }

    .survey-badge {
      .tab-badge {
        font-size: 10px;
        font-weight: 700;
        text-transform: capitalize;
        padding: 3px 8px;
        border-radius: 3px;
        display: inline-block;
        color: #6c7688;
        background-color: rgba(108, 118, 136, 0.1019607843);
        border: 1px solid #6c7688;

        &.pending {
          color: #6c7688;
          background-color: rgba(108, 118, 136, 0.1019607843);
          border: 1px solid #6c7688;
        }
      }
    }
  }

  .question-wrapper {
    // background-color: #eaf4ff;
    padding: 20px;
    border-top: 1px solid $border-color;
    width: 100%;

    @include for-size(desk-1536) {
      padding: 36px 20px;
    }

    @include for-size(desk-1366) {
      padding: 30px 20px;
    }

    &:first-child {
      border-top: none;
    }

    .question-title {
      font-size: 20px;
      font-weight: 700;
      color: $text-black;
      line-height: 26px;
      margin-bottom: 50px;

      @include for-size(desk-1536) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 36px;
      }

      @include for-size(desk-1366) {
        margin-bottom: 30px;
      }
    }

    .question-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px 120px;

      @include for-size(desk-1536) {
        gap: 36px 76px;
      }

      @include for-size(desk-1366) {
        gap: 36px 62px;
      }

      .questions-block {
        flex: 0 0 45%;

        @include for-size(desk-1536) {
          flex: 0 0 47%;
        }

        @include for-size(ipad-1070) {
          flex: 0 0 100%;
        }

        ::placeholder {
          color: $placeholder;
          font-weight: 400;
          font-size: 14px;
        }

        > span {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 10px;
          color: $text-black;
          display: inline-block;

          strong {
            color: $primary-color;
            text-transform: uppercase;
            font-size: inherit;
            font-weight: 700;
          }
        }

        .date-picker {
          > .input-group-text {
            border: 1px solid $primary-color;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e5f2ff;
            margin-bottom: 0;

            @include for-size(desk-1366) {
              height: 36px;
              line-height: 36px;
            }
          }

          input {
            border-left: 1px solid $primary-color !important;
            background-color: #fff;
            color: #333;
            height: 40px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            font-size: 13px;
            padding: 0 15px;
            caret-color: #007aff;

            @include for-size(desk-1366) {
              height: 36px;
              line-height: 36px;
            }
          }
        }

        input,
        textarea {
          @extend %default-form;
          transition: all 300ms ease;

          &:focus {
            background-color: #f2f8ff;
            border: 1px solid $primary-color;
            transition: all 300ms ease;
          }

          @include for-size(desk-1366) {
            height: 36px;
            line-height: 36px;
          }
        }

        .nice-select-form {
          height: 40px;
          line-height: 40px;
          padding: 0 15px;

          @include for-size(desk-1366) {
            height: 36px;
            line-height: 36px;
          }
        }

        .form-select {
          background-image: url("../images/form-drop-arrow.svg");
          background-size: 11px;
          background-position: right 15px center;
        }

        textarea {
          padding: 10px 15px;
        }

        small {
          @extend %error;
        }

        p {
          font-size: 12px;
          color: $text-mute;
          margin: 10px 0 0;
          font-weight: 400;
        }

        .field-rep-block {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 16px;

          .field-radio-block {
            flex: 1;
          }
        }

        .nice-select {
          .current {
            font-size: 14px;
            color: #8d8d8d;
            text-transform: capitalize;
            font-weight: 400;
          }
        }

        .radio-outline-primary,
        .checkbox-outline-primary {
          display: inline-block;
        }

        .checkbox-outline-primary {
          span {
            font-size: 13px;
            color: #333;
            font-weight: 400;
          }

          .checkmark {
            top: 0;
          }
        }

        .checkbox-list {
          flex-wrap: wrap;

          .field-radio-block {
            flex: 0 0 48%;

            @include for-size(phone-454) {
              flex: 0 0 46%;
            }
          }
        }
      }
    }
    &.single-column-question {
      .question-flex {
        .questions-block {
          width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }

  .custom-file-button {
    .image-upload a.img {
      img {
        width: 205px !important;
        height: auto !important;
        @include for-size(phone-575) {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }

  .template-button-block {
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include for-size(desk-1366) {
      padding: 24px 20px;
    }
    @include for-size(phone-575) {
      padding: 16px 16px;
      .parent-btn-grp {
        flex-wrap: wrap;
        gap: 10px;
        .conditionalBtns {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex-basis: 100%;
          gap: 10px;
          button:nth-child(1) {
            order: 1;
          }
          button:nth-child(2) {
            order: 0;
            margin: 0 !important;
          }
        }
        .btn {
          margin: 0;
          width: max-content;
        }
      }
    }
    .templates-btns {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      button {
        margin-right: 0 !important;
      }
    }

    .save-btn {
      @extend %button-primary;
      margin-right: 20px;

      @include for-size(desk-1366) {
        font-size: 14px;
        padding: 8px 16px;
      }
    }

    .cancel-btn {
      @extend %button-transparent-outline;

      @include for-size(desk-1366) {
        font-size: 14px;
        padding: 6px 16px;
      }
    }

    .refuse-btn {
      @extend %button-primary;
      background-color: $text-danger;

      @include for-size(desk-1366) {
        font-size: 14px;
        padding: 6px 16px;
      }

      &:hover,
      &:focus {
        background-color: #d64a4d;
        border: 1px solid #d64a4d;
      }
    }
    .btn-success {
      @extend %button-primary;
      background-color: #3bb537;
      color: #fff;

      @include for-size(desk-1440) {
        padding: 9px 20px;
        font-size: 14px;
        line-height: 19px;
      }

      &:hover,
      &:focus {
        background-color: transparent;
        color: #3bb537;
        border: 1px solid #3bb537;
      }
    }
    .btn-danger {
      padding: 8px 20px;
    }
  }

  .user-detail-card {
    background-color: #fff;
    padding: 20px;

    @include for-size(desk-1366) {
      padding: 20px;
    }

    .user-detail-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 16px;

      .user-data {
        flex-basis: 49%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;

        @include for-size(phone-600) {
          flex-basis: 48%;
        }

        @include for-size(ipad-991) {
          flex-basis: 100%;
        }

        @include for-size(phone-576) {
          flex-basis: 100%;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0;
        }

        small {
          font-size: 14px;
          font-weight: 700;
          color: $text-black;
          margin-bottom: 0;
          display: inline-block;
          width: 145px;

          @include for-size(ipad-1024) {
            width: 90px;
          }

          @include for-size(ipad-991) {
            width: 150px;
          }

          @include for-size(phone-576) {
            width: auto;
          }
        }

        h6,
        span {
          font-size: 14px;
          font-weight: 400;
          color: $text-black;
          margin-bottom: 0;
          line-height: 21px;
        }

        > span {
          @include for-size(phone-576) {
            display: none;
          }
        }
      }
    }

    .survey-note {
      margin-top: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      background-color: rgba(0, 122, 255, 0.03);
      padding: 20px;
      .survey-note-title {
        color: rgba(51, 51, 51, 1);
        font-weight: 700;
        font-size: 14px;
        display: block;
        margin-top: -3px;
      }
      .survey-note-text {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
        margin: 0;
      }
    }

    &.single-column-detail {
      .user-detail-wrapper {
        .user-data {
          @media screen and (max-width: 1270px) {
            flex: 0 0 100%;
          }

          small {
            @include for-size(desk-1366) {
              width: 90px;
            }

            @media screen and (max-width: 1270px) {
              width: 150px;
            }
          }
        }
      }
    }
  }

  .form-control,
  .form-select {
    font-size: 14px;
    min-height: 38px;
    box-shadow: unset;
  }
}

.custom-file-button {
  input[type="file"] {
    // margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }

    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: $primary-color;
      cursor: pointer;
      color: #fff !important;
    }
  }

  .input-group-text {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px 0 0 5px;
    border-right: 1px solid #fff;
    background-color: #f2f8ff;
    border: 1px solid $primary-color;
    color: $primary-color !important;
    height: 40px;
    font-weight: 700 !important;

    /*  @include for-size(desk-1366) {
     height: 3.6rem;
     line-height: 3.6rem;
 } */

    @include for-size(phone-576) {
      padding: 10px;
    }
  }

  ~ p {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #7a7a7a !important;
    margin: 10px 0 0 !important;
  }

  .file-form {
    @extend %default-form;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #8d8d8d !important;
    margin-left: 0 !important;
    border-left: none !important;

    /*  @include for-size(desk-1366) {
     height: 3.6rem;
     line-height: 3.6rem;
 } */
    @include for-size(desk-1440) {
      line-height: 40px !important;
    }
  }
}

.filter-btn-block {
  button {
    svg {
      fill: #999;
    }

    &.filter-applied {
      svg {
        fill: #333;
      }
    }

    .filter-badge {
      background: #007aff;
      border: 1.5px solid #f7fbff;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      right: -1px;
      z-index: 30;
      top: -2px;
    }
  }
}

.applicant-search {
  .search-control {
    @extend %default-form;
    border-width: 1px 0 1px 0;
    border-radius: 0;
    border-bottom: none;

    &:hover,
    &:focus {
      border-width: 1px 0 1px 0;
    }
  }

  ::placeholder {
    color: $placeholder;
    font-weight: 400;
    font-size: 14px;
  }
}

.applicant-filter {
  padding: 16px;

  @include for-size(desk-1440) {
    padding: 14px;
  }

  .nice-select,
  .form-select {
    &:focus {
      background-color: #fff !important;
    }
  }

  .form-select {
    background-image: url("../images/form-drop-arrow.svg");
    background-size: 11px;
    background-position: right 15px center;
  }

  ::placeholder {
    color: $placeholder;
    font-weight: 400;
    font-size: 14px;
  }

  .form-block-icon {
    position: relative;

    .form-icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    input {
      text-indent: 20px;
    }
  }

  input {
    @include for-size(desk-1440) {
      height: 36px;
      line-height: 36px;
      padding: 0 13px;
    }
  }

  input,
  textarea {
    @extend %default-form;
    transition: all 300ms ease;

    &:focus {
      background-color: #f2f8ff;
      border: 1px solid $primary-color;
      transition: all 300ms ease;
    }

    @include for-size(desk-1440) {
      height: 36px;
      line-height: 36px;
      padding: 0 13px;
    }
  }

  .form-block {
    .nice-select-form {
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      width: 100%;

      .current {
        display: inline-block;
        margin-right: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .date-group {
    display: flex;

    .form-group {
      flex: 1 1 100%;

      &:first-child {
        .form-block-icon {
          .status-form {
            border-radius: 5px 0 0 5px;
          }
        }
      }

      &:last-child {
        .form-block-icon {
          .status-form {
            border-radius: 0 5px 5px 0;
            border-left: none;
          }
        }
      }
    }
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .search-btn {
    @extend %button-primary;
    margin-right: 16px;

    @include for-size(desk-1440) {
      padding: 7px 14px;
      font-size: 13px;
      line-height: 19px;
    }
  }

  .reset-btn {
    @extend %button-transparent-outline;

    @include for-size(desk-1440) {
      padding: 7px 14px;
      font-size: 13px;
      line-height: 19px;
    }
  }
}

// .single-column-layout {
//   .white-wrapper0 {
//     &:last-of-type,&:only-child,&:last-child {
//       padding-bottom: 50px;
//     }
//   }
// }
/* *******************************
Questions template design end
******************************* */

/* ***************************
instruction-modal design start
******************************/
.instruction-modal {
  .instruction-block {
    padding: 15px 0;
    border: 1px solid #e0e0e0;
    border-width: 0 0 1px 0;

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    h6 {
      font-weight: 700;
      font-size: 16px;
      color: $text-black;
      margin-bottom: 4px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: $text-mute;
      margin-bottom: 0;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      color: $text-mute;
      margin-bottom: 0;
      display: inline-block;
    }

    .img-block {
      margin: 15px 0;
      width: 120px;
      height: 120px;

      > img {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        margin-bottom: 10px;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.no-image {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        background-color: #f7fbff;
        > img {
          width: 24px;
          height: 30px;
          border: none;
          margin: 0;
        }
      }

      .download-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        outline: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #333333b2;
        border: none;

        img {
        }
      }
    }
  }
}

/* *************************
instruction-modal design end
*****************************/

/* **********************
client modal design start
*************************/
.client-modal {
  .client-modal-flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

    .client-image {
      width: 80px;
      height: 80px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;

      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
      }
    }

    .client-content {
      small {
        font-weight: 400;
        font-size: 12px;
        display: inline-block;
        color: $text-mute;
        margin-bottom: 5px;
      }

      h6 {
        font-weight: 400;
        font-size: 16px;
        display: block;
        color: $text-black;
        margin-bottom: 0;
      }

      .client-content-block {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

/* ********************
client modal design end
***********************/

/* *********************
report-modal design start
*************************/
.report-modal {
  .modal-content {
    p {
      font-weight: 400;
      font-size: 14px;
      color: $text-gray;
      margin-bottom: 0;
    }

    ul {
      padding-left: 20px;
      margin: 10px 0;
      list-style-type: disc;

      li {
        list-style-type: disc;

        &::marker {
          color: #999;
          font-size: 16px;
        }

        p {
          color: $text-black;
        }
      }
    }
  }

  .field-rep-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
  }

  form {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;

    @include for-size(desk-1440) {
      padding-top: 16px;
      margin-top: 16px;
    }
  }

  &:has(.issue-type-radio) {
    ul + p {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $border-color;

      @include for-size(desk-1440) {
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .issue-type-radio {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;

    .radio-outline-primary {
      color: $text-black;
      font-size: 14px;
      font-weight: 400;
    }

    @include for-size(desk-1440) {
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }
}

/* ********************
report-modal design end
***********************/

/* *********************
round-modal design start
************************/
.round-modal {
  .round-content-wrapper {
    padding: 0 20px 20px;

    .round-content-block {
      padding: 15px 0;
      border: 1px solid #e0e0e0;
      border-width: 0 0 1px 0;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      h6 {
        font-weight: 700;
        font-size: 16px;
        display: block;
        color: $text-black;
        margin-bottom: 3px;

        span {
          color: $primary-color;
          font-size: inherit;
          font-weight: inherit;
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: $text-gray;
        margin-bottom: 0;
      }
    }
  }

  .modal-button-block {
    margin-top: 20px;

    .submit-btn {
      @extend %button-primary;
      margin-right: 16px;

      // &:hover,
      // &:focus {
      //     background-color: transparent;
      //     color: $text-danger;
      //     border-color: $text-danger
      // }

      @include for-size(desk-1440) {
        padding: 7px 14px;
        font-size: 13px;
        line-height: 19px;
      }
    }

    .cancel-btn {
      @extend %button-transparent-outline;

      // &:hover,
      // &:focus {
      //     border-color: $text-danger;
      //     background-color: transparent;
      //     color: $text-danger;
      //     border-color: $text-danger;
      // }

      @include for-size(desk-1440) {
        padding: 7px 14px;
        font-size: 13px;
        line-height: 19px;
      }
    }
  }

  .acknowledge-block {
    background-color: rgba(0, 122, 255, 0.05);
    padding: 14px 20px 20px;

    .form-media {
      display: flex;
      gap: 12px;

      label {
        display: inline-block;
        // width: 18px;
        // height: 18px;
      }

      // .checkmark {
      //     top: -1px;
      // }

      h6,
      label {
        font-size: 14px;
        font-weight: 400;
        color: $text-black;
        margin-bottom: 0;
      }
    }
  }
}

/* *******************
round-modal design end
**********************/

// Under Maintenance page css start
.under-maintenance {
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .text {
    color: #7a7a7a;
    font-weight: 400;
    line-height: 21.82px;
    @include for-size(ipad-992) {
      font-size: 14px;
    }
  }
  .middle-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .under-development-icon {
      align-items: center;
      background: #007aff0d;
      border-radius: 5px;
      display: flex;
      height: 70px;
      justify-content: center;
      margin-bottom: 20px;
      width: 70px;
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      width: 39%;
      @include for-size(ipad-1024) {
        width: 50%;
        font-size: 14px;
      }
      @include for-size(ipad-992) {
        width: 58%;
        font-size: 14px;
      }
      @include for-size(phone-575) {
        width: 100%;
        font-size: 14px;
      }
    }
    .mail-address {
      font-weight: 700;
      color: #333;
    }
  }
  .floating-icon-top {
    left: 20px;
    @include for-size(desk-1280) {
      width: 35%;
      img {
        width: 100%;
      }
    }
    @include for-size(ipad-992) {
      width: 60%;
      img {
        width: 100%;
      }
    }
    @include for-size(phone-575) {
      width: 54%;
      img {
        width: 100%;
      }
    }
  }
  .floating-icon-bottom {
    right: 20px;
    bottom: 20px;
    @include for-size(desk-1280) {
      width: 35%;
      img {
        width: 100%;
      }
    }
    @include for-size(ipad-992) {
      width: 60%;
      img {
        width: 100%;
      }
    }
    @include for-size(phone-575) {
      width: 54%;
      img {
        width: 100%;
      }
    }
  }
}
// Under Maintenance page css end
