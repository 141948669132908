.react-datepicker{
    font-family: inherit!important;
}
.react-datepicker__triangle{
    transform: translateX(-50%)!important;
    left: 50%!important;
}
.react-datepicker-wrapper input{
    text-indent: 25px;
    background-image: url(./../images/date-icon.svg);
    background-repeat: no-repeat;
    background-position: 15px center;
}
.react-datepicker{
    border: 1px solid #007aff !important;
}
.react-datepicker__header{
    background-color: rgba(0, 122, 255, 0.05)!important;
    border-bottom: 1px solid #007aff !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: #333!important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before{
    border-top-color: #007aff !important
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border-bottom-color: #007aff !important
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-width: 2px 2px 0 0!important;
    top: 13px!important;
    border-color: #007aff!important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: #007aff!important;
    color: #fff!important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color: rgba(0, 122, 255, 0.05)!important;
}
.react-datepicker__day--keyboard-selected:hover,.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover{
    background-color: #007aff!important;
    color: #fff!important;
    opacity: 0.8!important;
}
.react-datepicker__month-dropdown-container select,.react-datepicker__year-dropdown-container select{
    border-color: #007aff;
    padding: 2px;
    border-radius: 4px;
    margin: 6px 0;
    outline: none;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
    padding-right: 5px !important;
}