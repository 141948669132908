.position-fixed {
  position: fixed !important;
}

.rounded-3 {
  border-radius: var(--tblr-border-radius-lg) !important;
}

/* .bg-white {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-white-rgb), var(--tblr-bg-opacity)) !important;
} */

.me-2 {
  margin-right: 0.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.text-center {
  text-align: center !important;
}

.text-white {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-white-rgb), var(--tblr-text-opacity)) !important;
}

.alert {
  --tblr-alert-color: var(--tblr-muted);
  background: #ffffff;
  border: 1px var(--tblr-border-style) var(--tblr-border-color-translucent);
  border-left: 0.25rem var(--tblr-border-style) var(--tblr-alert-color);
  box-shadow: rgba(29, 39, 59, 0.05) 0 2px 4px 0;
}
.alert > :last-child {
  margin-bottom: 0;
}

.alert-important {
  border-color: transparent;
  background: var(--tblr-alert-color);
  color: #fff;
}
.alert-important .alert-icon,
.alert-important .alert-link {
  color: inherit;
}
.alert-important .alert-link:hover {
  color: inherit;
}

.alert-link, .alert-link:hover {
  color: var(--tblr-alert-color);
}

.alert-primary {
  --tblr-alert-color: var(--tblr-primary);
}

.alert-secondary {
  --tblr-alert-color: var(--tblr-secondary);
}

.alert-success {
  --tblr-alert-color: var(--tblr-success);
}

.alert-info {
  --tblr-alert-color: var(--tblr-info);
}

.alert-warning {
  --tblr-alert-color: var(--tblr-warning);
}

.alert-danger {
  --tblr-alert-color: var(--tblr-danger);
}

.alert-light {
  --tblr-alert-color: var(--tblr-light);
}

.alert-dark {
  --tblr-alert-color: var(--tblr-dark);
}

.alert-muted {
  --tblr-alert-color: var(--tblr-muted);
}

.alert-blue {
  --tblr-alert-color: var(--tblr-blue);
}

.alert-azure {
  --tblr-alert-color: var(--tblr-azure);
}

.alert-indigo {
  --tblr-alert-color: var(--tblr-indigo);
}

.alert-purple {
  --tblr-alert-color: var(--tblr-purple);
}

.alert-pink {
  --tblr-alert-color: var(--tblr-pink);
}

.alert-red {
  --tblr-alert-color: var(--tblr-red);
}

.alert-orange {
  --tblr-alert-color: var(--tblr-orange);
}

.alert-yellow {
  --tblr-alert-color: var(--tblr-yellow);
}

.alert-lime {
  --tblr-alert-color: var(--tblr-lime);
}

.alert-green {
  --tblr-alert-color: var(--tblr-green);
}

.alert-teal {
  --tblr-alert-color: var(--tblr-teal);
}

.alert-cyan {
  --tblr-alert-color: var(--tblr-cyan);
}

.alert-facebook {
  --tblr-alert-color: var(--tblr-facebook);
}

.alert-twitter {
  --tblr-alert-color: var(--tblr-twitter);
}

.alert-linkedin {
  --tblr-alert-color: var(--tblr-linkedin);
}

.alert-google {
  --tblr-alert-color: var(--tblr-google);
}

.alert-youtube {
  --tblr-alert-color: var(--tblr-youtube);
}

.alert-vimeo {
  --tblr-alert-color: var(--tblr-vimeo);
}

.alert-dribbble {
  --tblr-alert-color: var(--tblr-dribbble);
}

.alert-github {
  --tblr-alert-color: var(--tblr-github);
}

.alert-instagram {
  --tblr-alert-color: var(--tblr-instagram);
}

.alert-pinterest {
  --tblr-alert-color: var(--tblr-pinterest);
}

.alert-vk {
  --tblr-alert-color: var(--tblr-vk);
}

.alert-rss {
  --tblr-alert-color: var(--tblr-rss);
}

.alert-flickr {
  --tblr-alert-color: var(--tblr-flickr);
}

.alert-bitbucket {
  --tblr-alert-color: var(--tblr-bitbucket);
}

.alert-tabler {
  --tblr-alert-color: var(--tblr-tabler);
}

.alert-icon {
  color: var(--tblr-alert-color);
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: -0.125rem 1rem -0.125rem 0;
}

.alert-title {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: var(--tblr-font-weight-bold);
  margin-bottom: 0.25rem;
  color: var(--tblr-alert-color);
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: 3.875rem;
    pointer-events: auto;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%); */
    border-radius: 0.25rem;
    color: #fff;
    margin-top: 30px;
}

.toast-container {
  --tblr-toast-zindex: 1090;
  position: absolute;
  z-index: var(--tblr-toast-zindex);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  font-size: 62.5%;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--tblr-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--tblr-toast-padding-y) var(--tblr-toast-padding-x);
  color: var(--tblr-toast-header-color);
  background-color: var(--tblr-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--tblr-toast-border-width) solid var(--tblr-toast-header-border-color);
  border-top-left-radius: calc(var(--tblr-toast-border-radius) - var(--tblr-toast-border-width));
  border-top-right-radius: calc(var(--tblr-toast-border-radius) - var(--tblr-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--tblr-toast-padding-x));
  margin-left: var(--tblr-toast-padding-x);
}

.toast-body {
  padding: var(--tblr-toast-padding-x);
  word-wrap: break-word;
}

.mt-5 {
  margin-top: 6rem!important;
}
.translate-middle-x {
  transform: translateX(168%)!important;
}