@import "mixins";
@import "buttons";
@import "variables";

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.invalid-feedback {
  width: 100%;
  margin-top: 4px;
  font-size: 12px !important;
  color: #dc3545;
  font-weight: 500;
  display: block;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}

.max-450 {
  max-width: 450px;
}

.no-record-found {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
  text-align: center;
  display: block;
  width: 100%;
}

.text-muted {
  color: #7a7a7a !important;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.site-user-img {
  .data-table-img,
  .user-name-badge {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
    }

    span {
      font-weight: 700;
      font-size: 28px;
      background-color: rgba(0, 122, 255, 0.0509803922);
      color: #007aff;
      border-radius: 0.5rem;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #007aff;
      text-transform: uppercase;
      flex-shrink: 0;
    }
  }
}

.data-table-img {
  border-radius: 5px;
  border: 1px solid #e0e0e0 !important;
  width: 30px;
  height: 30px;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

.note-width {
  width: 300px;
  max-width: 300px;

  .table-data {
    max-width: 400px;
  }

  @include for-size(desk-1440) {
    width: 350px;
  }

  @include for-size(desk-1280) {
    width: 300px;
  }

  div {
    > p {
      width: 400px;

      @include for-size(desk-1440) {
        width: 350px;
      }

      @include for-size(desk-1280) {
        width: 300px;
      }
    }
  }
}

.add-resource-modal-form {
  label {
    font-size: 14px;
    font-weight: 400;
    color: #333;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  input[type="file"] {
    border-left-color: #007aff !important;
  }
}

.sm-title {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  text-transform: capitalize;
  margin-bottom: 10px;
}

option {
  font-size: 14px;
}

@include for-size(tablet-768) {
  .modal-dialog {
    width: 100vw !important;
    max-width: none !important;
    height: 100% !important;
    margin: 0 !important;

    .modal-content {
      height: 100%;
    }
  }
}

.toast {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.2);
  border-radius: 5px !important;
  border: none !important;
  padding: 16px;
  margin-top: 0;

  @include for-size(phone-576) {
    margin: 0 auto;
    width: 330px;
  }
}

.toast-container {
  position: fixed;
  right: 20px;
  top: 20px;

  @include for-size(phone-576) {
    right: 14px;
    top: 14px;
  }
}

.toast-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-right: 16px;

  span {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    background-color: #3bb537;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: self-start;
    &.primary {
      background-color: #007aff;
    }
  }

  h6 {
    color: $text-black;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  p {
    color: $text-mute;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 20px;
  }

  .toast-error-bg {
    span {
      background-color: #ee4549 !important;
    }
  }
}

.toast-close {
  position: absolute;
  right: 16px;
  top: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}

.extraClass {
  font-size: 20px !important;
  pointer-events: auto !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.react-tooltip {
  background-color: #fff !important;
  background: #fff;
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important;
  width: 250px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #333 !important;
  opacity: 1 !important;
  z-index: 10;
  box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.2);
}

.react-tooltip {
  background-color: #fff !important;
  background: #fff;
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important;
  width: 250px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #333 !important;
  opacity: 1 !important;
  z-index: 10;
  box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.2);
}

.react-tooltip-arrow {
  background: inherit;
  height: 12px;
  position: absolute;
  transform: rotate(45deg);
  width: 12px;
  border: 1px solid #e0e0e0;
  z-index: -1;
  border-width: 0px 1px 1px 0px;
  bottom: -5px !important;
}

.navigation .react-tooltip {
  width: max-content !important;
  position: fixed !important;
  transform: unset !important;
  visibility: hidden;
  transform: translate(0%, -38px) !important;
  bottom: auto;
  top: auto !important;

  .react-tooltip-arrow {
    border-width: 0px 0px 1px 0px !important;
  }

  @include for-size(ipad-1024) {
    display: none;
  }
}

.navigation.has-scroll .react-tooltip {
  transform: translate(0%, -142px) !important;
}

.custom-tooltip {
  opacity: 1 !important;

  .tooltip-inner {
    background-color: #fff;
    color: #333;
    box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.2);
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 6px 12px;
    font-family: "Nunito Sans", sans-serif;
    max-width: 550px;
    max-height: 300px;
    overflow: auto;
    .tooltip-title {
      font-weight: 700;
      font-family: "Nunito Sans", sans-serif;
    }
  }

  .tooltip-arrow:before {
    border-right-color: #fff !important;
  }

  &.name-tooltip {
    .tooltip-inner {
      max-width: 300px;
    }
  }

  &.tooltip-bottom {
    .tooltip-arrow:before {
      border-bottom-color: #fff !important;
      border-top-color: #fff;
      border-right-color: transparent !important;
    }
  }

  &.tooltip-top {
    .tooltip-arrow:before {
      border-top-color: #fff !important;
      border-right-color: transparent !important;
    }
  }

  &.text-start {
    .tooltip-inner {
      text-align: left !important;
    }
  }

  &.attachment-tooltip {
    .tooltip-inner {
      max-width: max-content;
    }
  }
}

// .navigation.active .react-tooltip {
//   visibility: visible;
// }

.navigation.active ul li:hover .react-tooltip {
  visibility: visible;
}

.navigation.active ul li:nth-child(5) + .react-tooltip,
.navigation.active ul li:nth-child(11) + .react-tooltip {
  margin-top: 18px;
}

.table thead tr th:first-child .table-head-data {
  justify-content: center;
}

.table thead tr th.not-first-child .table-head-data {
  justify-content: space-between;
}

.table thead tr > th.d-none + th {
  border-left: none !important;
}

.table tbody tr > td.d-none + td {
  border-left: none !important;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.client-contact-table .table thead tr th:first-child .table-head-data {
  justify-content: flex-start;
}

.form-logo {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.table-responsive th.status-table > .table-head-data {
  justify-content: center;
}

.table-footer .no-result-text {
  color: $text-gray;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
  text-align: center;
  display: block;
  width: 100%;
}

.accordion-button.collapsed {
  background-color: transparent;
  box-shadow: none !important;
}

.react-datepicker-wrapper > * .react-datepicker-wrapper {
  font-size: 16px !important;
}

.profile-column {
  width: 68px;
  max-width: 68px;
}

.action-w-40 {
  width: 40px;
  max-width: 40px;
}

.action-w-100 {
  width: 100px;
  max-width: 100px;
  .table-data,
  a {
    white-space: normal;
  }
}

.action-w-130 {
  width: 130px;
  max-width: 130px;
  .table-data {
    word-break: break-word;
    white-space: normal;
  }
}
.action-w-150 {
  width: 150px;
  max-width: 150px;
  .table-data {
    word-break: break-word;
    white-space: normal;
  }
}
.action-w-200 {
  width: 200px;
  max-width: 200px;
  .table-data {
    word-break: break-word;
    white-space: normal;
  }
}
.action-w-250 {
  width: 250px;
  max-width: 250px;
  .table-data {
    word-break: break-word;
    white-space: normal;
  }
}
.action-w-300 {
  width: 300px;
  max-width: 300px;
  .table-data {
    word-break: break-word;
    white-space: normal;
  }
}

.surveys-table-wrapper {
  .table-header .dropdown-menu {
    left: 0 !important;
  }

  .table-header .search-block .dropdown-center .dropdown-menu {
    left: 15px !important;
  }
}

.form-builder-card .card-form-container .droppable-area {
  min-height: 475px;
  // display: flex;
  // flex-direction: column;
}

// .create-template-body .card-form-container{
//     min-height: 475px;
// }
.editor-main .jodit-status-bar-link {
  display: none;
}

.single-column-question_1,
.white-wrapper1 {
  background-color: $bg-blue;
  padding: 20px 20px;

  .question-label {
    margin-bottom: 10px;
    display: block;
  }

  p {
    margin: 10px 0;
  }
  @include for-size(phone-575) {
    padding: 16px;
  }
}

.white-wrapper0 {
  padding: 20px 20px;

  .question-label {
    margin-bottom: 10px;
    display: block;
  }

  p {
    margin: 10px 0;
  }
  @include for-size(phone-575) {
    padding: 16px;
  }
}

.single-column-layout {
  .white-wrapper0 {
    &:last-of-type,&:only-child,&:last-child {
      padding-bottom: 50px;
    }
  }
}
.preview-radio-grp,
.preview-checkbox-grp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
}

.tab-sticky {
  position: sticky;
  top: 80px;
  height: max-content;
  @include for-size(ipad-1024) {
    position: static;
  }
}

.add-options-body {
  .subtitle {
    font-size: 14px;
    font-weight: 600;
  }
}

.double-column-layout {
  display: flex;
  flex-wrap: wrap;
}

.double-column-layout .single-column-question_1 ~ .white-wrapper1,
.double-column-layout .single-column-question_0 ~ .white-wrapper0 {
  min-width: 50%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1;
  @include for-size(phone-575) {
    min-width: 100%;
  }
}

.double-column-layout > .white-wrapper0 {
  min-width: 50%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1;
  @include for-size(phone-575) {
    min-width: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input.enable-spin-btn {
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;
}

input.enable-spin-btn::-webkit-outer-spin-button,
input.enable-spin-btn::-webkit-inner-spin-button {
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;
  margin: 0;
}

// CSS for report an issue page
.report-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.rounded-circle {
  font-weight: 700;
  font-size: 12px;
  background-color: rgba(0, 122, 255, 0.0509803922);
  color: #007aff;
  border-radius: 0.5rem;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #007aff;
  margin: 0 auto;
}

.react-tooltip__closing {
  display: none;
}

// React toaster css
.Toastify__toast-container {
  width: max-content !important;

  .Toastify__progress-bar {
    display: none;
  }

  .Toastify__toast-icon {
    width: 40px;
  }

  .Toastify__toast-body {
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    font-family: "Nunito Sans", sans-serif;
  }

  .Toastify__close-button {
    align-self: center;

    svg {
      fill: currentColor;
      height: 20px !important;
      width: 20px !important;
    }
  }
}

//React toaster css over

.survey-preview-form {
  .image-upload {
    button {
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      background-color: rgba(51, 51, 51, 0.4392156863);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .template-button-block {
    // margin-top: -40px;
  }
}

.file-thumbnail {
  width: 120px;
  height: 120px;
  background-color: #f2f2f2;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.under-development {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .under-development-icon {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background: rgba(0, 122, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  h3 {
    color: #333;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: #7a7a7a;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .go-to-dashboard-btn {
    border-radius: 5px;
    background-color: #007aff;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 19px;
    transition: all 300ms ease;
    border: 1px solid transparent;
    text-decoration: none;
    margin-top: 20px;

    &:hover {
      background-color: #0065d2;
      border: 1px solid #0065d2;
      color: #fff;
    }
  }
  @include for-size(phone-575) {
    padding: 15px;
  }
}

.survey-tooltip {
  p {
    color: #7a7a7a;

    small {
      color: #333333;
      font-weight: 600;
    }
  }
}

.tab-badge {
  font-size: 10px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 3px 8px;
  border-radius: 3px;

  &.completed {
    color: #3bb537 !important;
    background-color: #3bb53724 !important;
    border: 1px solid #3bb537 !important;
  }

  &.not-approved {
    color: #ee4549 !important;
    background-color: #ee45491a !important;
    border: 1px solid #ee4549 !important;
  }

  &.reported {
    color: #a32499 !important;
    background-color: #a324991a !important;
    border: 1px solid #a32499 !important;
  }

  &.late {
    color: #fc5d20 !important;
    background-color: #fc5d201a !important;
    border: 1px solid #fc5d20 !important;
  }

  &.scheduled {
    color: #027ffa !important;
    background-color: rgba(0, 122, 255, 0.08) !important;
    border: 1px solid #027ffa !important;
  }

  &.offer {
    color: #00bfa5 !important;
    background-color: #00bfa51a !important;
    border: 1px solid #00bfa5 !important;
  }

  &.pending {
    color: #6c7688 !important;
    background-color: #6c76881a !important;
    border: 1px solid #6c7688 !important;
  }

  &.hold {
    color: #a32499 !important;
    background-color: #a324991a !important;
    border: 1px solid #a32499 !important;
  }
}

.text-center {
  .table-head-data {
    justify-content: center !important;
  }
}

// Custom calendar css start
.custom-calendar {
  .status-grp {
    flex-wrap: wrap;
  }
  .fc-header-toolbar {
    @include for-size(phone-575) {
      flex-wrap: wrap;
      gap: 15px;
      justify-content: flex-start;
      .fc-toolbar-chunk:nth-child(1) {
        order: 1;
        .fc-button-group {
          button {
            height: 30px !important;
            font-size: 12px !important;
          }
          button:first-child,
          button:last-child {
            width: 30px;
          }
        }
      }
      .fc-toolbar-chunk:nth-child(2) {
        order: 0;
        flex-basis: 100%;
      }
      .fc-toolbar-chunk:nth-child(3) {
        order: 2;
        .fc-button-group {
          button {
            height: 30px !important;
            padding: 0 8px !important;
            font-size: 12px !important;
          }
        }
      }
    }
  }
  .fc-toolbar-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    text-transform: capitalize;
    margin: 0;
    @include for-size(phone-575) {
      font-size: 16px;
    }
  }

  .fc .fc-button-group > .fc-button {
    background-color: #007aff;
    border-color: #007aff;
    text-transform: capitalize;

    &:hover {
      opacity: 0.9;
    }
  }

  .fc-button-active {
    opacity: 0.9;
  }

  .fc-day-other {
    background-color: #eee;
  }
  //today
  .fc .fc-more-popover .fc-popover-body {
    max-height: 250px !important;
    overflow-y: auto;
  }

  .fc .fc-col-header-cell-cushion,
  .fc .fc-daygrid-day-number {
    color: #000;
    text-decoration: none;
    font-size: 10px;
  }

  .fc-header-toolbar .fc-toolbar-chunk:first-child .fc-button-group {
    gap: 1px;

    .fc-button {
      border: 1px solid #e0e0e0 !important;
      // border-radius: 5px;
      background-color: transparent;
      color: #333;
      width: 40px;
      height: 40px;
      padding: 0;
      font-size: 14px;

      &:nth-child(2) {
        padding-left: 12px;
        padding-right: 12px;
        width: max-content;
        border-radius: 0px;
      }

      &:hover {
        background-color: rgba(0, 122, 255, 0.0823529412);
        color: #007aff;
        border: 1px solid #007aff !important;
      }
    }
  }

  .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group {
    gap: 1px;

    .fc-button {
      border: 1px solid #e0e0e0 !important;
      // border-radius: 5px;
      background-color: transparent;
      color: #333;
      width: max-content;
      height: 40px;
      padding: 0 12px;
      font-size: 14px;

      &:hover,
      &.fc-button-active {
        background-color: rgba(0, 122, 255, 0.0823529412);
        color: #007aff;
        border: 1px solid #007aff !important;
      }
    }
  }

  .fc-h-event {
    background-color: transparent;
    border-color: transparent;
  }

  .fc-event-main {
    span {
      white-space: normal;
    }
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    width: 90%;
    margin: 0 auto;
    & > .fc-daygrid-event-harness {
      @include for-size(tablet-767) {
        display: none;
      }
    }
  }

  .fc-daygrid-more-link {
    @include for-size(phone-575) {
      white-space: normal;
      margin-top: -10px;
      font-size: 12px;
    }
  }

  .fc .fc-multimonth-multicol .fc-daygrid-more-link {
    border: 0;
    text-align: center;
  }

  .fc-view::-webkit-scrollbar {
    width: 6px;
  }

  .fc .fc-popover {
    z-index: 1000;
  }
  .fc-theme-standard th .fc-scroller-harness .fc-scroller {
    overflow: hidden !important;
  }
}
.mb-0.offcanvas .offcanvas-body {
  margin-bottom: 0 !important;
}
.checkbox-p-0 .checkbox {
  padding: 0;
}
.custom-sorting-title {
  position: absolute;
  width: 100%;
  top: 0;
  height: 37px;
  left: 0;
  padding: 8px 10px;
}
.start-survey-btn {
  position: absolute;
  top: 12px;
  right: 0;
}
.resource-rename-field {
  border: 1px solid #007aff;
  outline: none;
  box-shadow: unset;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 122, 255, 0.05);
}
.create-folder-field {
  background-color: #fff;
  color: #333;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 15px;
  caret-color: #007aff;
  outline: none;
  &:focus {
    background-color: #f2f8ff;
    border: 1px solid #007aff;
    transition: all 300ms ease;
  }
}
.badge-primary {
  width: 22px;
  height: 22px;
  background-color: #007aff;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  font-weight: 700;
}
.badge-white {
  width: 18px;
  height: 18px;
  background-color: #fff;
  color: #007aff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
}
.badge-inactive {
  width: 18px;
  height: 18px;
  background-color: #007aff;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
}
.css-1ygsqq8 {
  overflow: unset;
}

.resource-accordion {
  .create-folder-btn {
    position: absolute;
    right: 70px;
    z-index: 3;
  }
  .assign-survey-btn {
    right: 35px !important;
  }
}
.drag-field {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nice-select-form {
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    background-color: #fff;
    font-size: 14px;

    @include for-size(tablet-767) {
      width: 100%;
    }

    @include for-size(desk-1440) {
      height: 36px;
      line-height: 36px;
      padding: 0 13px;
    }

    .current {
      display: inline-block;
      margin-right: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }

  &.fixed-width {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
  }

  > .date-picker {
    > .input-group-text {
      border: 1px solid $primary-color;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e5f2ff;

      @include for-size(desk-1440) {
        padding: 8px 14px !important;
        height: 40px !important;
        line-height: 40px !important;
      }
    }

    input {
      border-left: 1px solid $primary-color !important;
    }
    .react-datepicker-wrapper {
      width: max-content;
      flex: 1 0 auto;
      input {
        text-indent: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-image: unset;
        &::placeholder {
          text-indent: 0;
        }
      }
    }
  }

  input {
    @extend %default-form;
    transition: all 300ms ease;

    &:focus {
      background-color: #f2f8ff;
      border: 1px solid $primary-color;
      transition: all 300ms ease;
    }

    @include for-size(desk-1440) {
      height: 40px;
      line-height: 40px;
      padding: 0 13px;
    }
  }

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #8d8d8d;
  }
}

.error-toaster {
  background: transparent;
  box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.2);
  width: 350px;
  height: 72px;
  .toast-container {
    position: unset;
    .toast {
      position: relative;
      top: -14px;
      right: 20px;
      height: 72px;
    }
  }
}

.dashboard-loader {
  @include for-size(ipad-991) {
    width: 100% !important;
  }
}
