.css-1fdsijx-ValueContainer,
.css-qbdosj-Input,
.css-qbdosj-Input>input,
.react-select__input-container,
.react-select__input,
.react-select__value-container {
    height: 100% !important;
}

.css-qbdosj-Input,
.react-select__input-container {
    padding: 0;
    height: 100%;
}

.css-b62m3t-container {
    border-radius: 5px !important;
}

.react-select__control {
    border: 1px solid #e0e0e0 !important;
    background-image: url(../images/form-drop-arrow.svg);
    background-size: 11px;
    background-position: right 15px center;
    background-repeat: no-repeat;
    border-radius: 5px !important;
    font-size: 13px;
    height: 40px !important;
}

.react-select__input-container,
.react-select__single-value {
    font-size: 13px;
}

.react-select__control--is-focused,
.react-select__value-container {
    height: 40px;
    font-size: 13px;
}

.react-select__control:hover {
    border: 1px solid #007aff !important;
}

.react-select__value-container {
    padding: 2px 26px 2px 11px !important;
}

.css-b62m3t-container span+span>div:first-child:focus,
.css-b62m3t-container:focus {
    background-color: #f2f8ff !important;
    box-shadow: none !important;
    outline: none !important;
}

/* .css-1u9des2-indicatorSeparator,
.css-1ok40vo-indicatorContainer>svg,
.css-tj5bde-Svg,
.react-select__indicators {
    display: none !important;
} */
.css-1u9des2-indicatorSeparator,
.css-1ok40vo-indicatorContainer,
.css-1xc3v61-indicatorContainer,
.react-select__indicator {
    display: none !important;
}

.css-b62m3t-container span:nth-child(2)+div {
    box-shadow: none !important;
}

.css-b62m3t-container span:nth-child(2)+div>div {
    max-height: 38px;
    align-content: center;
}

.css-b62m3t-container span:nth-child(2)+div:focus {
    background-color: #f2f8ff !important;
    border: 1px solid #007aff;
}


.react-select__menu-list {
    padding: 0 !important;
}

/* .react-select__menu-list {
    height: 200px !important;
} */

.react-select__menu>* {
    font-size: 16px;
}

.react-select__option--is-focused {
    background-color: #007aff15 !important;
    color: #333 !important;
}

.react-select__menu-notice {
    font-size: 14px;
    color: #8d8d8d !important;
}

.react-select__menu-list:has(.react-select__menu-notice) {
    height: auto !important;
}

.react-select__option:last-child {
    border-bottom: 0 !important;
}

.react-select__menu-list:-webkit-scrollbar {
    width: 4px;
}

.react-select__menu-list:-webkit-scrollbar-thumb {
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
    background-color: #e0e0e0;
}

.react-select__menu-list:-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

@media screen and (max-width:1440px) {
    .react-select__control {
        min-height: 40px !important;
        height: 40px !important;
    }

    .css-b62m3t-container+div {
        min-height: 40px !important;
        height: 40px !important;
    }
}

@media screen and (max-width:575px) {

    .add-brodcast-modal .css-1u9des2-indicatorSeparator,
    .add-brodcast-modal .css-1ok40vo-indicatorContainer,
    .add-brodcast-modal .css-1xc3v61-indicatorContainer,
    .add-brodcast-modal .react-select__indicator {
        display: block !important;
        position: relative;
    }

    .add-brodcast-modal .css-b62m3t-container span:nth-child(2)+div>div {
        align-content: flex-start;
    }

    .multiple-select>div {
        background-image: unset !important;
    }

    .css-h0ytr0-MultiValueGeneric {
        padding-right: 10px !important;
    }

    .remove-icon {
        display: none !important;
    }

    .css-1xc3v61-indicatorContainer:before {
        content: "";
        position: absolute;
        width: 300px;
        height: 100%;
        right: 0;
    }
}

/* .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled.react-datepicker__day-names, .react-datepicker__week, .react-datepicker__month-container, .react-datepicker, .react-datepicker>* .react-datepicker-wrapper>*, .react-datepicker-wrapper, .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled.react-datepicker__day-names, .react-datepicker__week, .react-datepicker__month-container, .react-datepicker, .react-datepicker>* .react-datepicker-wrapper>*, .react-datepicker-wrapper, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name, .react-datepicker__header, .react-datepicker__day-names, .react-datepicker__week, .react-datepicker__tab-loop__start, .react-datepicker__tab-loop, .react-datepicker__input-container, .react-datepicker-popper, .react-datepicker-popper>div {
    font-size: 16px !important;
} */

.react-datepicker>*,
.react-datepicker-wrapper>* .react-datepicker,
.react-datepicker-wrapper {
    font-size: 100% !important;
}

.css-1n6sfyn-MenuList {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.multiple-select>div {
    border: 1px solid #e0e0e0 !important;
    background-image: url(../images/form-drop-arrow.svg);
    background-size: 11px;
    background-position: right 15px center;
    background-repeat: no-repeat;
    border-radius: 5px !important;
    font-size: 14px;
    min-height: 40px !important;
}

.multiple-select>div:hover {
    border: 1px solid #007aff !important;
    /* background-color: #f2f8ff; */
}

.remove-icon {
    background-color: #999;
    color: #fff;
    height: 18px;
    width: 18px;
    margin: auto;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.remove-icon:hover {
    background-color: #EE4549;
}

.react-select-placeholder {
    font-size: 14px;
}

.multi-select-list-item {
    border-bottom: 1px solid #e0e0e0 !important;
    font-size: 12px;
    background-color: #fff;
}

.multi-select-list-item:hover {
    background-color: #007aff15;
}

.multi-select-list-item:last-child {
    border-bottom: 0;
}

.multi-select-list-item input {
    height: auto !important;
}

.css-1u9des2-indicatorSeparator+.css-15lsz6c-indicatorContainer svg {
    display: none;
}

.multiple-select ::-webkit-scrollbar {
    width: 0;
    height: 2px;
}

.multiple-select ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0);
}

.multiple-select ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(238, 238, 238, 0);
}

.custom-date-picker-header {
    padding: 0 10px;
}

.date-piker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.date-piker-header h6 {
    color: #333;
    font-weight: 700;
    margin: 0;
    font-size: 15px;
}

.month-year-grp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding: 0px 5px
}

.month-year-grp select,
.month-year-grp .year-dropdown-btn {
    width: 100%;
    margin: 0;
    height: 26px;
    padding: 0 10px !important;
    margin: 4px 0;
    border: 1px solid #007aff !important;
    background-color: #fff;
    outline: none;
    position: relative;
    text-align: left;
}

.month-year-grp .arrow-down {
    content: "";
    display: block;
    height: 9px;
    border-style: solid;
    position: absolute;
    width: 9px;
    border-color: #000;
    border-width: 2px 2px 0 0;
    top: 50%;
    left: 50%;
    transform: translate(32px, -6px) rotate(134deg);
    z-index: 1;
}

.month-year-grp .year-dropdown-btn:before {
    content: "";
    display: block;
    height: 9px;
    border-style: solid;
    position: absolute;
    width: 9px;
    border-color: #000;
    border-width: 2px 2px 0 0;
    top: 50%;
    left: 50%;
    transform: translate(32px, -6px) rotate(134deg);
}

.year-dropdown {
    box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.1);
}

.year-dropdown div:hover {
    background-color: #006ce1 !important;
    color: #fff !important;
}


.date-navigation-btn {
    padding: 0 !important;
    background-color: transparent;
    width: 30px;
    height: 30px;
    border: 0 !important;
    position: relative;
}

.date-navigation-btn::before {
    content: "";
    display: block;
    height: 9px;
    border-style: solid;
    position: absolute;
    width: 9px;
    border-color: #007aff;
    border-width: 2px 2px 0 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(225deg);
}

.date-navigation-next:before {
    transform: translate(-50%, -50%) rotate(45deg);
}