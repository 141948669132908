@import "mixins";
@import "buttons";
@import "variables";

body {
  margin: 0;
  padding: 0;
  font-family: $nunito;
}

textarea {
  resize: unset;
}

.bg-blue {
  background-color: $bg-blue;
}

.mb-20 {
  margin-bottom: 16px;

  @include for-size(tablet-768) {
    margin-bottom: 16px;
  }
}

.flex-1 {
  flex: 1;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.screen-title {
  margin: 0 0 16px;

  @include for-size(desk-1440) {
    margin: 0 0 14px;
  }

  .main-title {
    @extend %title;

    @include for-size(desk-1440) {
      font-size: 18px;
    }
  }
}

.label-filled {
  color: $primary-color;
}

.w-90 {
  width: 90%;
}

.border-img {
  border: 1px solid $border-color !important;
}

.not-allowed {
  cursor: not-allowed;
}

.no-button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.canvas-height {
  height: calc(100% - 72px);
  overflow-y: auto;
}

:disabled {
  color: #7a7a7a;
}

/* *****************
scrollbar design start
********************/
.permissions-block::-webkit-scrollbar,
.canvas-height::-webkit-scrollbar,
.offcanvas-body::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar,
.question-filter-wrapper::-webkit-scrollbar,
.applicant-dropdown-menu::-webkit-scrollbar,
.my-questions-block::-webkit-scrollbar,
.create-template-body .card-form-container::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.permissions-block::-webkit-scrollbar-thumb,
.canvas-height::-webkit-scrollbar-thumb,
.question-filter-wrapper::-webkit-scrollbar-thumb,
.offcanvas-body::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb,
.applicant-dropdown-menu::-webkit-scrollbar-thumb,
.my-questions-block::-webkit-scrollbar-thumb,
.create-template-body .card-form-container::-webkit-scrollbar-thumb {
  border-radius: 16px;
  // -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
  -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0);
}

.permissions-block::-webkit-scrollbar-track,
.canvas-height::-webkit-scrollbar-track,
.question-filter-wrapper::-webkit-scrollbar-track,
.offcanvas-body::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track,
.applicant-dropdown-menu::-webkit-scrollbar-track,
.my-questions-block::-webkit-scrollbar-track,
.create-template-body .card-form-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eeeeee00;
}

/* *****************
scrollbar design end
********************/

/* ***********************
nice select design start
*************************/
.nice-select {
  @extend %default-form;
  background-color: transparent;
  font-size: 14px;
  padding: 9px 20px;
  color: $text-black;
  text-indent: 0;
  height: auto;
  width: 100%;
  text-indent: 0;
  line-height: 37px;
  float: none;

  .current {
    font-size: 14px;
    color: $text-mute;
    text-transform: capitalize;
  }

  .list {
    width: 100%;
    height: auto;
    overflow-y: auto;

    .option {
      font-size: 14px;
      color: $text-black;
      margin: 0 0px;
      padding: 0px 10px 0;
      text-indent: 0;
      text-transform: capitalize;
      font-weight: 500;

      &:first-child {
        display: none;
      }

      &:hover {
        background-color: #007aff15;
      }

      &:focus {
        background-color: #007aff15;
        color: #fff;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }

  &::after {
    display: none;
  }
}

/* ***********************
nice select design end
*************************/

/* ***********************
offcanvas design start
*************************/
.offcanvas {
  width: 500px !important;

  .offcanvas-header {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(35, 31, 32, 0.1);
    padding: 18px 20px;
    position: relative;
    z-index: 20;

    .offcanvas-title {
      width: 100%;
    }

    h5 {
      font-weight: bold;
      font-size: 18px;
      color: $text-black;
      margin-bottom: 0;
      text-transform: capitalize;
      padding-right: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: $primary-color;
        font-size: inherit;
      }
    }

    .btn-close {
      position: absolute;
      right: 24px;
      // top: calc(50% - 6px);
      z-index: 20;
      // background: none !important;
      border-radius: 0;
      border: none;
      width: 20px;
      height: 20px;
      padding: 0;
      opacity: 1;
      filter: opacity(0.4);
      background-size: 13px;

      @include for-size(desk-1440) {
        width: 16px;
        height: 16px;
      }

      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .help-tooltip {
      .table-tooltip {
        background: #fff;
        border: 1px solid #d9d9d9 !important;
        border-radius: 5px;
        max-width: 340px;
        width: 340px;
        position: absolute;
        z-index: 10;
        top: 32px;
        right: 0;
        left: -22px;
        padding: 14px;
        display: none;
        box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);

        /* @include for-size(ipad-991) {
                    top: 32px;
                    right: -7px;
                    left: auto;
                } */

        @include for-size(phone-520) {
          width: 260px;
        }

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 9px solid #d9d9d9;
          position: absolute;
          left: 19px;
          top: -9px;
          rotate: 180deg;
        }

        &::after {
          content: "";
          width: 0;
          height: 0;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 8px solid #fff;
          position: absolute;
          left: 20px;
          top: -8px;
          rotate: 180deg;
        }

        > p {
          font-size: 12px;
          font-weight: 400;
          color: $text-black;
          white-space: normal;
          word-wrap: break-word;
          margin-bottom: 0;
        }
      }

      &:hover {
        .table-tooltip {
          display: block;
        }
      }
    }
  }
  &.willing-to-travel-offcanvas .offcanvas-body {
    margin-bottom: 0 !important;
  }
  &.project-types-offcanvas .offcanvas-body {
    margin-bottom: 0 !important;
  }

  .offcanvas-body {
    padding: 0;
    height: calc(100vh - 130px);
    margin: auto 0 70px !important;

    &.custom-canvas-body {
      padding: 0;
      margin: 0;
    }
  }
}

.canvas-border-top {
  border-top: 1px solid #e0e0e0;
  box-shadow: 0px 4px 10px rgb(35 31 32 / 10%);
}

/* ***********************
offcanvas design start
*************************/

/* **************
table design start
*****************/
.table-responsive {
  background-color: #fff;

  .table > :not(caption) > * > * {
    border-width: 1px;
    border-color: #e0e0e0;
    padding: 8px 10px;

    @include for-size(tablet-767) {
      padding: 10px 12px;
    }
  }

  .table {
    vertical-align: middle;
    white-space: nowrap;
    margin-bottom: 0;

    th {
      text-transform: capitalize;
      color: $text-black;
      font-size: 14px;
      font-weight: 700;
      border: 1px solid #e0e0e0;
      border-top: none;
      // background-color: #007aff15;
      background-color: #f7fbff;
      padding: 8px 10px;
      position: relative;
      border-bottom: none;

      @include for-size(desk-1440) {
        font-size: 13px;
        padding: 6px 8px;
      }

      &:first-child {
        border-left: none;
      }

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.no-sorting {
        .table-head-data {
          justify-content: center;
        }
      }

      .checkbox-outline-primary {
        height: 18px;
        width: 18px;
        margin: 0 auto;
      }

      .sorting-btn {
        padding: 0;
        margin: 0;
        background-color: transparent;
        outline: none;
        border: none;
        margin-left: 16px;
        display: inline-block;
        // position: absolute;
        // right: 10px;
      }

      .sort-icon {
        margin-left: 16px;
      }

      .table-head-data {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          text-transform: capitalize;
          color: $text-black;
          font-size: 14px;
          font-weight: 700;
          margin: 0;

          @include for-size(desk-1440) {
            font-size: 13px;
          }
        }
      }
    }

    td {
      @include for-size(desk-1440) {
        font-size: 13px;
        padding: 6px 8px;
      }
    }

    td,
    td > * {
      color: $text-black;
      font-size: 14px;
      font-weight: 400;

      @include for-size(desk-1440) {
        font-size: 13px;
      }

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.name-field {
        max-width: 250px;
        white-space: initial;

        .table-data p,
        .table-data a {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }

        p,
        a {
          width: 100%;
          white-space: initial;
        }
      }

      .checkbox-outline-primary {
        height: 18px;
        width: 18px;
        margin: 0 auto;
      }

      .tab-invoice-status {
        span {
          color: $text-black;
          font-size: 14px;
          font-weight: 400;
          display: inline-block;
          padding-left: 8px;

          @include for-size(desk-1440) {
            padding: 13px;
          }
        }
      }

      .tab-badge {
        font-size: 10px;
        font-weight: 700;
        text-transform: capitalize;
        padding: 3px 8px;
        border-radius: 3px;

        &.completed {
          color: #3bb537;
          background-color: #3bb53724;
          border: 1px solid #3bb537;
        }

        &.not-approved {
          color: #ee4549;
          background-color: #ee45491a;
          border: 1px solid #ee4549;
        }

        &.reported {
          color: #a32499;
          background-color: #a324991a;
          border: 1px solid #a32499;
        }

        &.late {
          color: #fc5d20;
          background-color: #fc5d201a;
          border: 1px solid #fc5d20;
        }

        &.scheduled {
          // color: #7a3eff;
          // background-color: #7a3eff1a;
          // border: 1px solid #7a3eff;
          color: #027ffa;
          background-color: rgba(0, 122, 255, 0.08);
          border: 1px solid #027ffa;
        }

        &.offer {
          color: #00bfa5;
          background-color: #00bfa51a;
          border: 1px solid #00bfa5;
        }

        &.pending {
          color: #6c7688;
          background-color: #6c76881a;
          border: 1px solid #6c7688;
        }

        &.hold {
          color: #a32499;
          background-color: #a324991a;
          border: 1px solid #a32499;
        }
      }

      .table-icon {
        a,
        button {
          display: inline-block;

          &:not(:last-child) {
            margin-right: 12px;
          }
        }
        .disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      .table-data {
        p,
        .table-notes {
          margin: 0;
          font-size: inherit;
          line-height: 18px;
          white-space: normal;

          a {
            text-decoration: none;
            font-size: inherit;
            color: $primary-color;

            &:hover {
              ~ .table-tooltip {
                display: block !important;
              }
            }
          }
        }

        a {
          text-decoration: none;
          font-size: inherit;
          color: $primary-color;

          &:hover {
            ~ .table-tooltip {
              display: block !important;
            }
          }
        }

        &.clip-data {
          > a,
          > p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 250px;
            // height: 40px;
            word-break: break-word;
            white-space: normal;
          }
        }

        &:has(img) {
          position: relative;
        }

        .close-icon {
          position: absolute;
          right: 0;
          top: 0;
        }

        a {
          position: relative;

          &:hover {
            ~ .table-tooltip {
              display: block !important;
            }

            .table-tooltip {
              display: block !important;
            }
          }
        }

        &.note-data {
          p {
            width: 400px;

            @include for-size(desk-1440) {
              width: 350px;
            }

            @include for-size(desk-1280) {
              width: 300px;
            }
          }
        }

        &.round-alert {
          display: inline-flex;
          align-items: flex-start;
          gap: 10px;

          p {
            align-self: flex-end;
          }

          a {
            width: 16px;
            height: 16px;
            margin-bottom: 6px;
            display: inline-block;

            .table-tooltip {
              width: 300px;
              padding: 6px 10px;

              strong {
                font-size: inherit;
              }

              &.attachment-tooltip {
                gap: 6px;
                bottom: -45px;
                top: auto;
                right: -19px;
                left: auto;
                width: auto;
              }

              &.survey-tooltip {
                left: -15px;
                width: 250px;
                top: 35px;

                &::before {
                  left: 10px;
                }

                &::after {
                  right: auto;
                  left: 11px;
                }
              }
            }
          }
        }

        &.survey-site {
          display: inline-flex;
          align-items: center;
          white-space: nowrap;

          p {
            white-space: nowrap;
          }
        }
      }

      .emp-badge {
        background-color: #ff9948;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #fff;
        position: absolute;
        bottom: -2px;
        right: -2px;

        img {
          width: 8px;
          height: 8px;
        }

        &.employee {
          background-color: #00bbd4 !important;
        }
      }

      .emp-badge-block {
        .emp-tooltip {
          background: #fff;
          border: 1px solid #d9d9d9 !important;
          border-radius: 5px;
          width: max-content;
          position: absolute;
          z-index: 10;
          top: 39px;
          right: 0;
          left: -14px;
          padding: 8px;
          display: none;

          &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 9px solid #d9d9d9;
            position: absolute;
            left: 19px;
            top: -9px;
            rotate: 180deg;
          }

          &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 8px solid #fff;
            position: absolute;
            left: 20px;
            top: -8px;
            rotate: 180deg;
          }

          > p {
            font-size: 12px;
            font-weight: 400;
            color: $text-black;
            white-space: normal;
            word-wrap: normal;
          }
        }

        &:hover {
          .emp-tooltip {
            display: block;
          }
        }
      }

      .user-name-badge {
        .rounded-circle,
        span {
          font-weight: 700;
          font-size: 14px;
          background-color: #007aff0d;
          color: $primary-color;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $primary-color;
          margin: 0 auto;
          text-transform: uppercase;
        }
      }

      .badge-outline {
        @extend %badge-sm-outline;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .table-survey-box {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        &:not(:last-child) {
          margin-bottom: 6px;
        }

        .assign-survey-btn {
          @extend %button-fill-icon;
          width: 18px;
          height: 18px;

          svg {
            width: 10px;
            height: 10px;
            margin-right: 0;
          }
        }

        .assigned-survey-btn {
          @extend %button-primary-outline;
          width: 18px;
          height: 18px;
          display: grid;
          place-items: center;
          padding: 0;

          svg,
          path {
            width: 10px;
            height: 10px;
            fill: $primary-color;
            margin-right: 0;
          }
        }

        span {
          @extend %label;
          font-weight: 600;
          margin-bottom: 0;

          @include for-size(desk-1440) {
            font-size: 13px;
          }
        }
      }

      .custom-link {
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: $text-black;
        text-decoration: none;
        text-transform: capitalize;
        gap: 6px;

        @include for-size(desk-1440) {
          font-size: 13px;
        }

        img {
          transform: translateX(0);
          transition: all 300ms ease-in;
          font-size: 13px;
        }

        strong {
          color: $primary-color;
          font-size: inherit;
          font-weight: 700;
        }

        &:hover {
          img {
            transform: translateX(6px);
            transition: all 300ms ease-in;
          }
        }
      }

      .table-tooltip {
        background: #fff;
        border: 1px solid #d9d9d9 !important;
        border-radius: 5px;
        position: absolute;
        // z-index: 10;
        // top: 26px;
        // right: 0;
        // left: -14px;
        z-index: 10;
        bottom: 0;
        top: 26px;
        background-color: #fff;
        height: fit-content;
        width: 250px;
        right: 0;
        left: auto;
        padding: 12px;
        display: none;
        font-size: 12px;
        font-weight: 400;
        color: $text-black;
        white-space: normal;
        word-wrap: break-word;

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 9px solid #d9d9d9;
          position: absolute;
          right: 19px;
          top: -9px;
          rotate: 180deg;
        }

        &::after {
          content: "";
          width: 0;
          height: 0;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 8px solid #fff;
          position: absolute;
          right: 20px;
          top: -8px;
          rotate: 180deg;
        }
      }

      .table-sm-btn {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      .approve {
        background-color: #3bb537;
      }

      .reject {
        background-color: #ee4549;
      }

      .table-user-img {
        border-radius: 5px;
        border: 1px solid $border-color;
      }

      .survey-btn-block {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .survey-table-btn {
          width: 20px;
          height: 20px;
          border-radius: 5px;
          background-color: #f8f8f8;
          border: 1px solid transparent;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          cursor: not-allowed;

          svg,
          path {
            fill: #d0d0d0;
            width: 12px;
            height: 8px;
          }

          &.correct {
            background-color: #3bb537;
            cursor: pointer;

            svg,
            path {
              fill: #fff;
              width: 12px;
              height: 8px;
            }
          }

          &.incorrect {
            background-color: #ee4549;
            cursor: pointer;

            svg,
            path {
              fill: #fff;
              width: 12px;
              height: 8px;
            }
          }
        }

        &.disabled {
          pointer-events: none;

          .survey-table-btn {
            background-color: #f8f8f8;

            svg {
              fill: #d0d0d0;

              path {
                fill: #d0d0d0;
              }
            }
          }
        }
      }
    }

    div {
      .company-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        background-color: #898989;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include for-size(tablet-767) {
          margin-right: 3px;
        }
      }

      &:hover {
        ~ .table-tooltip {
          display: block !important;
        }
      }
    }

    .selected-row {
      background-color: #f2f2f2;
    }

    .active-status {
      color: $text-success;
    }

    .inactive-status {
      color: $text-danger;
    }

    .onhold-status {
      color: $text-warning;
    }

    .w-45 {
      width: 40px;
    }
  }

  &.accordion-table {
    border-radius: 5px;

    table {
      tbody {
        tr {
          td {
            .align-middle {
              img {
                @include for-size(desk-1440) {
                  height: 14px;
                  width: 14px;
                }
              }
            }
          }

          &:last-child {
            td {
              border-bottom: 0;

              &:last-child {
                border-radius: 0 0 0 5px;
              }

              &:last-child {
                border-radius: 0 0 5px 0;
              }
            }
          }

          &:only-child {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }

  .row-selected {
    background-color: #ebf4ff;
  }
}

.accordion-table-footer {
  padding: 16px;
  border-top: 1px solid $border-color;

  .table-footer-btn {
    @extend %button-primary;
    padding: 6px 16px;
  }
}

/* *************
table design end  
****************/

/* ********************** 
table header design start
*************************/
.table-wrapper {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.1);
}

.nowrap {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0 0;
  padding: 16px;
  background-color: #fff;

  @include for-size(desk-1440) {
    padding: 14px;
  }

  &.nowrap {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  &.search-entries {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @include for-size(phone-480) {
      flex-direction: column-reverse;
      align-items: stretch;
      gap: 16px;
      justify-content: flex-start;
    }
  }

  &.status-entries {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @include for-size(phone-600) {
      flex-direction: column-reverse;
      align-items: stretch;
      gap: 16px;
      justify-content: flex-start;
    }
  }

  &.filter-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @include for-size(phone-600) {
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }

  @include for-size(phone-600) {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  ::placeholder {
    color: $placeholder;
    font-weight: 400;
    font-size: 14px;
  }

  h5 {
    @extend %title;

    @include for-size(desk-1440) {
      font-size: 18px;
    }
  }

  .form-header-btn {
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 5px 16px;
    transition: all 300ms ease;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;

    @include for-size(desk-1440) {
      padding: 7px 14px;
      font-size: 13px;
      line-height: 19px;
    }

    @include for-size(tablet-768) {
      font-size: 0;
      padding: 0;
      width: 34px;
      height: 34px;
      justify-content: center;
    }

    img {
      margin-right: 5px;

      @include for-size(tablet-768) {
        margin: 0;
        height: 10px;
      }
    }

    &:first-child {
      background-color: #3bb537;
      margin-right: 16px;
    }

    &:last-child {
      background-color: $text-danger;
    }
  }

  .search-form {
    @extend %default-form;
    height: 34px;
    width: 300px;
    line-height: 34px;

    @include for-size(desk-1440) {
      width: 260px;
      height: 32px;
      line-height: 32px;
    }

    @include for-size(tablet-768) {
      width: auto;
    }

    @include for-size(phone-600) {
      width: 100%;
    }
  }

  .dropdown-toggle-split {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    padding: 0 8px;

    &:hover {
      border: 1px solid $primary-color;
      color: $primary-color;
      background-color: #007aff15;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .table-buttons-block {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;

    @include for-size(tablet-768) {
      gap: 10px;
    }

    .table-action-btn {
      @extend %button-primary-outline;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      gap: 6px;
      line-height: 19px;
      border: 1px solid $border-color;
      color: $text-black;
      background-color: #fafafa;

      @include for-size(desk-1440) {
        padding: 6px 14px;
      }

      @include for-size(tablet-768) {
        font-size: 0;
        padding: 0;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0;
      }

      svg,
      path {
        fill: #999;

        @include for-size(tablet-768) {
          margin-right: 0;
        }
      }

      &:hover,
      &.active {
        background-color: #007aff15;
        color: $primary-color;
        border: 1px solid $primary-color;

        svg,
        path {
          fill: $primary-color;
        }
      }

      &:has(.dropdown-toggle) {
        &::before {
          content: "|";
          font-size: 0;
          right: 15px;
          position: absolute;
          margin: 0 14px;
          display: block;
          color: #e0e0e0;
          width: 1px;
          border-right: 1px solid #e0e0e0;

          @include for-size(tablet-768) {
            display: none;
          }
        }
      }

      &.export-btn {
        padding-right: 14px;
        border-right: 1px solid transparent;

        @include for-size(tablet-768) {
          padding-right: 0;
        }

        &:hover,
        &:focus {
          background-color: #007aff15;
          color: $primary-color;
          border: 1px solid $primary-color;
          border-right: 1px solid $primary-color;

          svg,
          path {
            fill: $primary-color;
          }

          &::before {
            color: $primary-color;
            border-right: 1px solid $primary-color;
          }
        }
      }
    }

    .column-btn {
      @extend %button-primary-outline;
      width: 34px;
      height: 33px;
      display: grid;
      place-items: center;
      padding: 0;

      svg {
        height: 16px;
        width: 16px;
        margin-right: 0;
      }

      &:hover,
      &:focus {
        svg,
        path {
          fill: #fff;
        }
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      width: 130px;
      border: 1px solid $border-color;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);
      top: 10px !important;
      left: -50px !important;
      max-width: 130px;
      min-width: 130px;
      padding: 5px 3px;

      .checkbox-group {
        padding: 6px;

        button {
          display: inline-flex;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 600;
          padding: 4px 8px;
          border: none;
          transition: all 300ms ease;
          color: #1a1a1a;
          text-transform: capitalize;
          border: 1px solid transparent;
          background-color: transparent;
          width: 100%;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          svg,
          path {
            fill: #333;
            transition: all 300ms ease;
          }

          &:hover,
          &:focus {
            box-shadow: none;
            outline: none;
            border: 1px solid $primary-color;
            color: #333;
            background-color: #007aff15;

            svg,
            path {
              fill: $primary-color;
              transition: all 300ms ease;
            }
          }

          &::after {
            border-right-color: $primary-color;
            border-left-color: $primary-color;
          }
        }
      }

      @include for-size(phone-576) {
        margin-left: 50px !important;
      }
    }

    .round-add-btn {
      @extend %button-primary;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      gap: 6px;
      line-height: 19px;

      @include for-size(desk-1440) {
        padding: 6px 14px;
      }

      @include for-size(tablet-768) {
        font-size: 0;
        padding: 0;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0;
      }

      svg {
        @include for-size(tablet-768) {
          margin-right: 0;
        }
      }
    }
  }

  .entries-block {
    @extend %flex-row-center;
    gap: 10px;

    .entries-form {
      @extend %default-form;
      height: 34px;
      width: 70px;
      line-height: 34px;

      @include for-size(desk-1440) {
        height: 32px;
        line-height: 32px;
      }
    }

    span {
      @extend %content;
    }

    .form-select {
      background-image: url("../images/form-drop-arrow.svg");
      background-size: 11px;
      background-position: right 10px center;
    }

    ::placeholder {
      color: $placeholder;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .change-status-block {
    @extend %flex-row-center;
    gap: 10px;

    span {
      @extend %content;
    }

    .entries-form {
      @extend %default-form;
      height: 34px;
      width: 130px;
      line-height: 34px;
    }

    .form-select {
      background-image: url("../images/form-drop-arrow.svg");
      background-size: 11px;
      background-position: right 10px center;
    }

    ::placeholder {
      color: $placeholder;
      font-weight: 400;
      font-size: 14px;
    }

    .delete-btn {
      @extend %button-fill-icon;
      background-color: $text-danger;

      svg {
        margin-right: 0;
      }

      &:hover,
      &:focus {
        border-color: $text-danger;

        svg,
        path {
          fill: $text-danger;
        }
      }
    }
  }

  .nav-tabs {
    border: none;

    > .nav-item {
      border: none;

      &:not(:last-child) {
        margin-right: 30px;
      }

      .nav-link {
        font-weight: 700;
        font-size: 20px;
        color: $text-black;
        border: none;
        position: relative;
        padding: 0;

        @include for-size(desk-1440) {
          font-size: 18px;
        }

        &.active {
          color: $primary-color !important;
          border: none;

          &::after {
            content: "";
            position: absolute;
            bottom: -18px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $primary-color;

            @include for-size(desk-1440) {
              bottom: -16px;
            }

            @include for-size(phone-600) {
              bottom: 0;
            }
          }
        }

        &:hover {
          border: none;
        }
      }
    }
  }

  .column-btn {
    @extend %button-primary-outline;
    width: 34px;
    height: 33px;
    display: grid;
    place-items: center;
    padding: 0;
    border: 1px solid #e0e0e0;
    background-color: #fafafa;

    &.bg-red {
      border: 1px solid #ee4549;
      background-color: #ee4549;

      svg,
      path {
        fill: #fff;
      }

      &:hover {
        border: 1px solid #ee4549;
        background-color: rgb(238 68 71 / 10%);

        svg,
        path {
          fill: #ee4549;
        }
      }
    }

    @include for-size(desk-1440) {
      width: 32px;
      height: 32px;
    }

    svg,
    path {
      height: 16px;
      width: 16px;
      margin-right: 0;
      fill: #999;

      @include for-size(desk-1440) {
        height: 14px;
        width: 14px;
      }
    }

    &:hover,
    &:focus {
      background-color: #007aff15;
      color: #007aff;
      border: 1px solid #007aff;

      svg,
      path {
        fill: $primary-color;
      }
    }

    &::after {
      display: none;
    }
  }

  .search-block {
    display: inline-flex;
    align-items: center;
    gap: 16px;

    .dropdown-center {
      position: relative;

      .dropdown-menu {
        width: 170px;
        border: 1px solid $border-color;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);
        // top: 10px !important;
        // left: 15px !important;

        h6 {
          color: $text-black;
          font-size: 14px;
          font-weight: 700;
          padding: 12px;
          border-bottom: 1px solid $border-color;
        }

        .checkbox-group {
          padding: 12px;

          span {
            font-size: 14px;
            color: $text-black;
          }

          label {
            &:not(:last-child) {
              margin-bottom: 12px;
            }

            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }

        &::before {
          content: "";
          // width: 0;
          // height: 0;
          // border-left: 10px solid transparent;
          // border-right: 10px solid transparent;
          // border-top: 9px solid #d9d9d9;
          // position: absolute;
          // right: 19px;
          // top: -9px;
          // rotate: 180deg;

          // @include for-size(ipad-991) {
          //   right: 5px;
          //   left: auto;
          // }
        }

        &::after {
          content: "";
          // width: 0;
          // height: 0;
          // border-left: 9px solid transparent;
          // border-right: 9px solid transparent;
          // border-top: 8px solid #fff;
          // position: absolute;
          // right: 20px;
          // top: -8px;
          // rotate: 180deg;

          // @include for-size(ipad-991) {
          //   right: 6px;
          //   left: auto;
          // }
        }
      }
    }
  }
}

.form-seperator {
  padding: 8px 16px;
  background-color: rgba(0, 122, 255, 0.05);

  @include for-size(desk-1440) {
    padding: 8px 14px;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    color: $primary-color;
    text-transform: capitalize;
    display: inline-block;
  }
}

.active-filter {
  display: block !important;
}

.filter-search-wrapper {
  display: none;
  background-color: #fff;

  .filter-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 15px;
    padding: 16px;
    border-radius: 0;
    border-bottom: 1px solid $border-color;
    background-color: #007aff15;
    margin-bottom: 0;
    position: relative;

    @include for-size(ipad-991) {
      flex-wrap: wrap;
      gap: 12px;
      padding: 12px;
    }

    .form-group {
      flex-basis: 16%;

      @include for-size(desk-1199) {
        flex-basis: 24%;
      }

      label {
        @extend %label;

        @include for-size(desk-1440) {
          margin-bottom: 8px;
          font-size: 13px;
        }
      }

      .status-form {
        @extend %default-form;
        height: 40px;
        float: none;
        line-height: 40px;

        @include for-size(desk-1440) {
          height: 36px;
          line-height: 36px;
          padding: 0 13px;
        }

        &:focus {
          background-color: #fff !important;
        }
      }

      .nice-select,
      .form-select {
        &:focus {
          background-color: #fff !important;
        }
      }

      .form-select {
        background-image: url("../images/form-drop-arrow.svg");
        background-size: 11px;
        background-position: right 15px center;
      }

      ::placeholder {
        color: $placeholder;
        font-weight: 400;
        font-size: 14px;
      }

      @include for-size(ipad-991) {
        width: 100%;
        flex-basis: 100%;
      }

      .form-block-icon {
        position: relative;

        .form-icon {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
        }

        input {
          text-indent: 25px;

          @include for-size(desk-1440) {
            text-indent: 24px;
          }
        }
      }
    }

    .filter-btn {
      @extend %button-primary;
      width: auto;
      padding: 8px 16px;
      align-self: flex-end;

      &:first-child {
        margin-right: 15px;
      }

      @include for-size(desk-1440) {
        padding: 6px 12px;
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 2px;
      }

      &.reset-btn {
        @extend %button-transparent-outline;
        padding: 8px 16px;

        @include for-size(desk-1440) {
          padding: 6px 12px;
          font-size: 13px;
          line-height: 19px;
        }
      }
    }

    &.extra-fields {
      @include for-size(desk-1440) {
        flex-wrap: wrap;
        gap: 16px;
      }

      @include for-size(desk-1280) {
        flex-wrap: wrap;
        gap: 16px;
      }

      .form-group {
        flex: 1;

        @include for-size(desk-1440) {
          flex: 0 0 16%;
        }

        @include for-size(desk-1280) {
          flex: 0 0 49%;
        }

        @media screen and (max-width: 1123px) {
          flex: 0 0 48%;
        }

        @include for-size(ipad-1024) {
          flex: 0 0 48%;
        }

        @include for-size(tablet-768) {
          flex: 0 0 100%;
        }
      }
    }

    .date-group {
      display: flex;
      max-width: 320px;

      @include for-size(desk-1280) {
        flex: 0 0 49%;
        max-width: 100%;
      }

      @include for-size(ipad-1024) {
        flex: 0 0 98%;
      }

      @include for-size(ipad-991) {
        width: 98%;
        flex-basis: 98%;
      }

      @include for-size(tablet-768) {
        flex: 0 0 100%;
      }

      .form-group {
        flex: 1 1 100%;

        &:first-child {
          .form-block-icon {
            .status-form {
              border-radius: 5px 0 0 5px;
            }
          }
        }

        &:last-child {
          .form-block-icon {
            .status-form {
              border-radius: 0 5px 5px 0;
              border-left: none;
            }
          }
        }
      }
    }
  }
}

/* ******************** 
table header design end
***********************/

/* *************************
table footer design start 
************************* */
.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  padding: 16px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;

  @include for-size(desk-1440) {
    padding: 14px;
  }

  @include for-size(phone-576) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  p,
  .table-footer-text {
    @extend %content;

    @include for-size(desk-1440) {
      font-size: 13px;
    }
  }

  .table-view-btn {
    @extend %button-primary;
    padding: 6px 16px;

    @include for-size(desk-1440) {
      padding: 6px 12px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  .pagination {
    margin-bottom: 0;
  }

  .pagination-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    .table-next-prev {
      @extend %flex-row-center;
      gap: 10px;

      .table-prev-next-btn {
        @extend %button-primary-outline;
        padding: 0;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        cursor: pointer;

        svg,
        path {
          margin: 0;
        }

        &:hover,
        .active {
          svg,
          path {
            fill: #fff;
          }
        }
      }
    }

    button[disabled] {
      background-color: #fff !important;
      color: #333 !important;
      border: 1px solid #e0e0e0 !important;

      svg,
      path {
        fill: #999 !important;
      }
    }

    .table-pages {
      @extend %flex-row-center;
      gap: 10px;

      span {
        @extend %content;

        @include for-size(desk-1440) {
          font-size: 13px;
        }
      }

      .default-form {
        width: 70px;
        // width: 60px;
        /* Initial width */
        // min-width: 60px;
        height: 34px;
        line-height: 34px;
        @extend %default-form;
      }
    }
  }
}

/* ***********************
table footer design end 
*********************** */

/* ***************************
card-form-wrapper design start
*****************************/
.card-form-wrapper {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.1);

  .form-hr {
    border-bottom: 1px solid $border-color;
  }

  .form-card-block {
    .form-header {
      border-bottom: 1px solid $border-color;
      padding: 16px;

      @include for-size(desk-1440) {
        padding: 14px;
      }

      h5 {
        @extend %title;

        @include for-size(desk-1440) {
          font-size: 18px;
        }
      }

      .form-header-btn {
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        padding: 5px 16px;
        transition: all 300ms ease;
        border: 1px solid transparent;
        display: inline-flex;
        align-items: center;

        @include for-size(desk-1440) {
          padding: 7px 14px;
          font-size: 13px;
          line-height: 19px;
        }

        @include for-size(tablet-768) {
          font-size: 0;
          padding: 0;
          width: 34px;
          height: 34px;
          justify-content: center;
        }

        img {
          margin-right: 5px;

          @include for-size(tablet-768) {
            margin: 0;
            height: 10px;
          }
        }

        &:first-child {
          background-color: #3bb537;
          margin-right: 16px;
        }

        &:last-child {
          background-color: $text-danger;
        }
      }

      &:has(.form-header-btn) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &.form-header-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-size(tablet-767) {
          flex-direction: column;
          align-items: stretch;
          justify-content: center;
          gap: 16px;
        }

        .form-block {
          .nice-select-form {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
            width: 400px;

            @include for-size(desk-1440) {
              height: 36px;
              line-height: 36px;
              padding: 0 13px;
            }

            @include for-size(tablet-767) {
              width: 100%;
            }

            .current {
              display: inline-block;
              margin-right: 16px;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;
            }
          }
        }
      }

      .preview-btn {
        @extend %button-primary;
        display: inline-flex;
        align-items: center;
        gap: 6px;
        padding: 6px 16px;

        svg {
          width: 17px;
          height: 10px;
        }
      }
    }

    .form-builder-card.create-template-body {
      .card-form-container {
        height: calc(100vh - 450px);
        overflow: auto;
        min-height: 564px;
      }
    }

    .card-form {
      .card-form-container {
        padding: 16px 16px 0;

        @include for-size(desk-1440) {
          padding: 14px 14px 0;
        }

        .form-notes {
          margin-bottom: 16px;

          @include for-size(desk-1440) {
            margin-bottom: 14px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: $text-gray;
            margin-bottom: 0;
          }
        }

        .table-survey-box {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          &:not(:last-child) {
            margin-bottom: 6px;
          }

          .assign-survey-btn {
            @extend %button-fill-icon;
            width: 18px;
            height: 18px;

            svg {
              width: 10px;
              height: 10px;
              margin-right: 0;
            }
          }

          .assigned-survey-btn {
            @extend %button-primary-outline;
            width: 18px;
            height: 18px;
            display: grid;
            place-items: center;
            padding: 0;

            svg,
            path {
              width: 10px;
              height: 10px;
              fill: $primary-color;
              margin-right: 0;
            }
          }

          span {
            @extend %label;
            font-weight: 600;
            margin-bottom: 0;

            @include for-size(desk-1440) {
              font-size: 13px;
            }
          }
        }

        .form-block {
          margin-bottom: 16px;
          width: 100%;

          @include for-size(desk-1440) {
            margin-bottom: 14px;
          }

          label {
            @extend %label;
            display: inline-flex;
            align-items: center;
          }

          .button-form-flex {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
          }

          .no-button {
            border: 1px solid $border-color;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            flex-shrink: 0;

            @include for-size(desk-1440) {
              width: 36px;
              height: 36px;
            }
          }

          .image-upload {
            position: relative;
            display: inline-block;

            .profile-pic {
              width: 120px;
              height: 120px;
              object-fit: cover;
            }

            button {
              border: none;
              outline: none;
              padding: 0;
              margin: 0;
              background-color: #33333370;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              position: absolute;
              top: 10px;
              right: 10px;
            }

            > img {
              border-radius: 5px;
              border: 1px solid $border-color;
            }
          }

          input,
          textarea {
            @extend %default-form;
            transition: all 300ms ease;

            &:focus {
              background-color: #f2f8ff;
              border: 1px solid $primary-color;
              transition: all 300ms ease;
            }

            @include for-size(desk-1440) {
              height: 40px;
              line-height: 40px;
              padding: 0 13px;
            }

            &[disabled] {
              background-color: hsl(0, 0%, 95%);
              color: hsl(0, 0%, 60%) !important;
              border-color: #e0e0e0;
            }
          }

          .nice-select-form {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;

            @include for-size(desk-1440) {
              height: 40px;
              line-height: 40px;
              padding: 0 13px;
            }
          }

          .pwd-form {
            position: relative;

            .eye-icon {
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
            }

            input[type="text"]:focus ~ label,
            input[type="email"]:focus ~ label,
            input[type="password"]:focus + label {
              color: $primary-color !important;
            }
          }

          .form-select {
            background-image: url("../images/form-drop-arrow.svg");
            background-size: 11px;
            background-position: right 15px center;
          }

          textarea {
            padding: 8px 15px;
            line-height: inherit;
            resize: unset;
          }

          small {
            @extend %error;
          }

          ::placeholder {
            color: $placeholder;
            font-weight: 400;
            font-size: 14px;
          }

          .form-block-icon {
            position: relative;

            .form-icon {
              position: absolute;
              top: 50%;
              left: 15px;
              transform: translateY(-50%);
            }

            input {
              text-indent: 20px;

              @include for-size(desk-1440) {
                text-indent: 24px;
              }
            }
          }

          .field-rep-block {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 16px;
          }

          .table-tooltip {
            background: #fff;
            border: 1px solid #d9d9d9 !important;
            border-radius: 5px;
            max-width: max-content;
            width: max-content;
            position: absolute;
            z-index: 10;
            top: 24px;
            right: 0;
            left: -22px;
            padding: 12px;
            display: none;
            box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);

            @include for-size(phone-520) {
              width: 260px;
            }

            &::before {
              content: "";
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 9px solid #d9d9d9;
              position: absolute;
              left: 23px;
              top: -9px;
              rotate: 180deg;
            }

            &::after {
              content: "";
              width: 0;
              height: 0;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-top: 8px solid #fff;
              position: absolute;
              left: 24px;
              top: -8px;
              rotate: 180deg;
            }

            > p {
              font-size: 12px;
              font-weight: 400;
              color: $text-black;
              white-space: normal;
              word-wrap: break-word;
              margin-bottom: 0;
            }
          }

          .add-end-date {
            @include for-size(tablet-767) {
              margin-bottom: 16px;
            }
          }

          .help-icon {
            width: 14px;
            height: 14px;
            margin-left: 5px;

            &:hover {
              ~ .table-tooltip {
                display: block;
              }
            }
          }
        }

        .survey-question-form {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .form-control {
            height: 49px;
            background-color: #f2f8ff;
            border: 1px dashed #e0e0e0;

            @include for-size(desk-1440) {
              height: 42px;
              line-height: 42px;
            }

            &:hover {
              background-color: #f2f8ff;
              border: 1px dashed $primary-color;
            }

            &:focus {
              background-color: #f2f8ff;
              border: 1px dashed $primary-color;
            }
          }

          &.form-builder-header {
            .form-builder-control {
              height: 67px;
              line-height: 67px;
            }

            ::placeholder {
              font-weight: 700;
              font-size: 20px;
              color: #8d8d8d;
            }
          }
        }

        .form-media {
          display: flex;
          align-items: flex-start;
          gap: 12px;
          margin-bottom: 16px;

          h6 {
            font-size: 14px;
            font-weight: 400;
            color: $text-black;
            margin-bottom: 3px;
          }

          small {
            font-size: 12px;
            font-weight: 400;
            color: $placeholder;
          }

          .form-media {
            width: 18px;
            height: 18px;
            display: inline-block;
          }

          label {
            display: inline-block;
            min-width: 18px;
            height: 18px;
          }
        }
      }

      .form-seperator {
        padding: 8px 16px;
        background-color: rgba(0, 122, 255, 0.05);

        span {
          font-size: 14px;
          font-weight: 600;
          color: $primary-color;
          text-transform: capitalize;
          display: inline-block;
        }
      }

      .card-form-footer {
        border-top: 1px solid $border-color;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-size(desk-1440) {
          padding: 14px;
          gap: 14px;
        }

        @include for-size(desk-1280) {
          flex-direction: column;
          align-items: flex-start;
        }

        @include for-size(tablet-768) {
          gap: 16px;
        }

        .submit-btn {
          @extend %button-primary;

          @include for-size(desk-1440) {
            padding: 7px 14px;
            font-size: 13px;
            line-height: 19px;
          }
        }

        .cancel-btn {
          @extend %button-transparent-outline;

          @include for-size(desk-1440) {
            padding: 7px 14px;
            font-size: 13px;
            line-height: 19px;
          }
        }

        .success-btn {
          @extend %button-primary;
          background-color: #3bb537;
          color: #fff;

          @include for-size(desk-1440) {
            padding: 7px 14px;
            font-size: 13px;
            line-height: 19px;
          }

          &:hover,
          &:focus {
            background-color: transparent;
            color: #3bb537;
            border: 1px solid #3bb537;
          }
        }

        button {
          &:not(:last-child) {
            margin-right: 16px;
          }
        }

        small {
          color: $text-gray;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          display: inline-block;
        }

        .templates-btns {
          @include for-size(phone-425) {
            width: 100%;
          }

          .btn {
            @include for-size(phone-425) {
              width: 100%;
            }

            &:not(:last-child) {
              @include for-size(phone-425) {
                margin-bottom: 16px;
              }
            }
          }
        }

        &:has(.templates-btns) {
          @include for-size(desk-1536) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
          }
        }
      }

      .form-subtitle {
        margin: 0 0 16px;

        h6 {
          font-size: 16px;
          font-weight: 700;
          color: $text-black;
          text-transform: capitalize;
          margin-bottom: 0;
        }
      }

      &.profile-form {
        .card-form-footer {
          border-top: 0;
        }
      }
    }
  }
}

/* *************************
card-form-wrapper design end
****************************/

/* **********************
common radio design start
*************************/
.radio-checked {
  // border: 1px solid $primary-color !important;
  background-color: #007aff0d;
}

.no-outline {
  border: 0 !important;
  background-color: transparent;
}

.green-checked {
  border: 1px solid #3bb537 !important;
  background-color: #3bb53714;

  .checkmark {
    border: 1px solid #3bb537 !important;

    &::after {
      background-color: #3bb537 !important;
    }
  }
}

.red-checked {
  border: 1px solid #ee4549 !important;
  background-color: #fdf0f1;

  .checkmark {
    border: 1px solid #ee4549 !important;

    &::after {
      background-color: #ee4549 !important;
    }
  }
}

.radio-outline-primary {
  display: flex;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        display: block;
        background-color: #fff !important;
        border: 1px solid $primary-color;

        &::after {
          background: $primary-color;
        }
      }
    }
  }

  span {
    color: $text-black;
    font-size: 14px;
    font-weight: 400;
  }

  .checkmark {
    background-color: #fff;
    // border: 2px solid #e0e0e0;
    border: 2.5px solid #9e9e9e;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      bottom: 0;
      margin: auto;
      border: 0;
      background: #fff;
      transform: rotate(45deg);
    }
  }
}

/* ********************
common radio design end
***********************/

/* *************************
common checkbox design start
****************************/
.checkbox-checked {
  border: 1px solid $primary-color !important;
  background-color: #007aff0d;
}

.checkbox-outline-primary {
  display: flex;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: $primary-color !important;
        border: 2px solid transparent !important;

        &::after {
          display: block;
          border-color: #fff;
        }
      }
    }
  }

  span {
    font-size: 13px;
    color: #898989;
    font-weight: 400;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    background: #fff;
    // border: 2px solid #e0e0e0;
    border: 2.5px solid #9e9e9e;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 0;
      top: -2px;
      right: 0;
      width: 6px;
      bottom: 0;
      margin: auto;
      height: 11px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
}

/* **********************
common checkbox design end
************************/

/* *************************
common uncheckbox design start
****************************/

/* ****************
switch design start
*******************/
.switch {
  position: relative;
  display: inline-block;
  padding-left: 0;
  width: 36px;
  height: 20px;
  height: 16px;

  span {
    font-size: 13px;

    &:not(.slider) {
      position: relative;
      top: -2px;
      /* -2px */
      cursor: pointer;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: #3bb537;

        &::before {
          -webkit-transform: translateX(15px);
          -ms-transform: translateX(15px);
          transform: translateX(15px);
        }
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    width: 36px;
    height: 20px;
    /* 42px */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    /* 34px */
    background-color: #c9c9c9;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 14px;
      /* 24px */
      width: 14px;
      /* 24px */
      left: 4px;
      /* -1px */
      bottom: 3px;
      /* -4px */
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 /* 3px 1px -2px 2px 2px 1px 5px */ rgba(0, 0, 0, 0.12);
    }
  }
}

/* ****************
switch design end
*******************/

.uncheckbox {
  height: 18px;
  width: 18px;
  margin: 0 auto;
}

.uncheckbox-outline-primary {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: transparent !important;
        border: 2px solid $primary-color !important;

        &::after {
          display: block;
          border-color: $primary-color;
        }
      }
    }
  }

  span {
    font-size: 13px;
    color: #898989;
    font-weight: 400;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    background: #fff;
    border: 0.1px solid #e0e0e0;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      right: 0;
      width: 6px;
      bottom: 0;
      margin: 0;
      height: 0;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%);
    }
  }
}

/* **********************
common un-checkbox design end
************************/

/* *******************
accordion design start
**********************/
.accordion {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: $box-shadow;

  .accordion-item {
    border: none;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .accordion-header {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include for-size(desk-1440) {
        padding: 14px;
      }

      .accordion-button {
        @extend %title;
        padding: 0;
        border: none;
        background-color: transparent;

        @include for-size(desk-1440) {
          font-size: 18px;
        }

        &:focus {
          box-shadow: none;
          outline: none;
          border: none;
        }

        &:not(.collapsed) {
          box-shadow: none !important;
        }
      }

      .assign-survey-btn {
        @extend %button-fill-icon;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 64px;
        z-index: 3;

        @include for-size(desk-1440) {
          width: 20px;
          height: 20px;
        }

        svg {
          width: 10px;
          height: 10px;
          margin-right: 0;
        }
      }

      .create-folder-btn {
        @extend %button-fill-icon;
        width: 24px;
        height: 24px;

        @include for-size(desk-1440) {
          width: 20px;
          height: 20px;
        }

        svg {
          width: 13px;
          height: auto;
          margin-right: 0;
        }
      }
    }

    .accordion-collapse {
      border-radius: 0 !important;
      border-bottom: none;

      .accordion-body {
        padding: 16px;
        border-top: 1px solid $border-color;

        .card-form-wrapper {
          box-shadow: none;
        }

        .no-result-text {
          color: #7a7a7a;
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          text-transform: capitalize;
          text-align: center;
          display: block;
          width: 100%;
        }
      }
    }
  }

  .accordion-button::after {
    content: "";
    background-image: url("../images/arrow-down.svg");
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: initial;
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    display: inline-block;
  }

  .accordion-button:not(.collapsed)::after {
    rotate: 0deg;
  }
}

/* *****************
accordion design end
********************/

/* ***************
modal design start
******************/
.modal {
  border-radius: 5px;

  .modal-dialog {
    border-radius: 5px;

    .modal-content {
      border-radius: 5px;
      border: 0;
    }

    .modal-header {
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(35, 31, 32, 0.1);
      padding: 16px 20px;
      position: relative;
      z-index: 1;
      // border-bottom: none;

      @include for-size(desk-1440) {
        padding: 14px 14px;
      }

      .btn-close {
        height: 12px;
        width: 12px;
        padding: 0;
        margin: 0;
        opacity: 1;
        box-shadow: none;
        background: none;
        outline: none;
        margin-right: 0;
        display: flex;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .modal-title {
        @extend %title;
        font-size: 18px;
      }
    }

    .modal-body {
      background-color: #fff;
      padding: 20px;
      margin-top: 0;
      border-radius: 0 0 5px 5px;

      @include for-size(desk-1440) {
        padding: 0 14px 14px;
        margin-top: 14px;
      }
    }

    .delete-modal-content {
      .modal-button-block {
        .submit-btn {
          @extend %button-primary;
          margin-right: 16px;
          background-color: #ee4549;

          &:hover {
            background-color: transparent;
            border-color: #ee4549;
            color: #ee4549;
          }
        }

        .cancel-btn {
          @extend %button-transparent-outline;

          &:hover {
            background-color: transparent;
            border-color: #ee4549;
            color: #ee4549;
          }
        }
      }

      .note {
        font-weight: 400;
        color: #7a7a7a;
        width: 80%;
      }
    }

    .form-block {
      margin-bottom: 16px;
      width: 100%;

      @include for-size(tablet-768) {
        margin-bottom: 16px;
      }

      label {
        @extend %label;
      }

      input,
      textarea {
        @extend %default-form;
        transition: all 300ms ease;

        &:focus {
          background-color: #f2f8ff;
          border: 1px solid $primary-color;
          transition: all 300ms ease;
        }
      }

      .nice-select-form {
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
      }

      .pwd-form {
        position: relative;

        .eye-icon {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .form-select {
        background-image: url("../images/form-drop-arrow.svg");
        background-size: 11px;
        background-position: right 15px center;
      }

      small {
        @extend %error;
      }

      ::placeholder {
        color: $placeholder;
        font-weight: 400;
        font-size: 14px;
      }

      .form-block-icon {
        position: relative;

        .form-icon {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
        }

        input {
          text-indent: 20px;
        }
      }
    }
  }
}

/* *************
modal design end
****************/

.custom-file-button {
  input[type="file"] {
    // margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }

    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: $primary-color;
      cursor: pointer;
      color: #fff !important;
    }
  }

  .input-group-text {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px 0 0 5px;
    border-right: 1px solid #fff;
    background-color: #f2f8ff;
    border: 1px solid $primary-color;
    color: $primary-color !important;
    height: 40px;
    font-weight: 700 !important;

    @include for-size(desk-1440) {
      padding: 8px 14px !important;
      height: 40px !important;
      line-height: 36px !important;
    }
  }

  + p {
    font-size: 12px;
    font-weight: 400;
    color: #7a7a7a;
    margin: 10px 0 0;

    @include for-size(desk-1440) {
      margin: 6px 0 0;
    }
  }

  .file-form {
    @extend %default-form;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #8d8d8d !important;
    margin-left: 0 !important;
    border-left: none !important;
  }
}

/* *****************
nav tabs design start
********************/
.nav-tabs {
  border: none;

  > .nav-item {
    border: none;

    &:not(:last-child) {
      margin-right: 30px;
    }

    .nav-link {
      font-weight: 700;
      font-size: 18px;
      color: $text-black;
      border: none;
      position: relative;
      padding: 0;

      &.active {
        color: $primary-color;
        border: none;

        &::after {
          content: "";
          position: absolute;
          bottom: -18px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $primary-color;

          @include for-size(desk-1440) {
            bottom: -14px;
          }
        }
      }

      &:hover {
        border: none;
      }
    }
  }
}

/* ****************
nav tabs design end
******************/

.custom-card {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: $box-shadow;

  .card-header {
    background-color: #f2f8ff;
    padding: 16px 0;
    border-radius: 5px 5px 0 0;

    @include for-size(desk-1440) {
      padding: 14px 0;
    }

    @include for-size(600) {
      width: 100%;
      overflow-y: auto;
      overflow-x: auto;
    }

    .nav-tabs {
      @include for-size(600) {
        flex-wrap: nowrap;
        width: 350px;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .nav-item {
        flex: 1;
        margin: 0;

        .nav-link {
          margin: auto;
          background-color: transparent;
          width: 100%;

          @include for-size(tablet-768) {
            font-size: 18px;
          }
        }
      }
    }
  }

  .card-body {
    .question-tab-content {
      background-color: #f2f8ff;
      border-radius: 0 0 5px 5px;

      .tab-pane {
        .note {
          margin: 0;
          padding: 16px;
          font-size: 14px;
          background: #fff;
          color: #7a7a7a;
        }
      }
    }

    .element-wrapper {
      padding: 16px;
      background-color: #fff;
      border-radius: 0 0 5px 5px;

      p {
        @extend %content;
        color: $text-gray;
      }

      .element-drag-list {
        margin-top: 20px;

        @include for-size(desk-1440) {
          margin-top: 16px;
        }

        .drag-block {
          border: 1px solid #e0e0e0;
          padding: 12px 16px;
          border-radius: 5px;
          min-width: 100% !important;

          @include for-size(desk-1440) {
            padding: 8px 12px;
          }

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          > div {
            // display: inline-flex;
            // align-items: center;

            svg,
            path {
              margin-right: 16px;
              fill: $text-black;

              @include for-size(desk-1440) {
                margin-right: 8px;
                width: 16px;
                height: 14px;
              }
            }

            span {
              @extend %content;
              font-weight: 600;
              text-transform: capitalize;
            }
          }

          img {
            visibility: hidden;
          }

          &:hover {
            border: 1px solid $primary-color;
            transition: all 300ms ease-in;
            background-color: #f2f8ff;
            cursor: pointer;

            > div {
              svg,
              path {
                fill: $primary-color;
              }
            }

            img {
              visibility: visible;
            }
          }
        }
      }
    }

    .question-search {
      .search-control {
        @extend %default-form;
        border-width: 1px;
        border-radius: 5px;
      }

      ::placeholder {
        color: $placeholder;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .question-filter-wrapper {
      overflow-x: scroll;
      width: calc(100% - 16px);
      background-color: #f2f8ff;
      border-top: 1px solid #e0e0e0;

      .question-filter-block {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 16px;
        align-items: center;
        flex-wrap: nowrap;
        margin: 16px;

        @include for-size(desk-1440) {
          margin: 14px;
        }

        .question-filter-btn {
          @extend %button-transparent-outline;
          white-space: nowrap;

          @include for-size(desk-1440) {
            padding: 6px 14px;
          }

          &.active {
            background-color: $primary-color;
            color: #fff;
            border: 1px solid transparent;
          }

          &:last-child {
            margin-right: 16px;
          }
        }
      }
    }

    .my-questions-block {
      padding: 16px;
      background-color: #fff;
      height: calc(100vh - 380px);
      overflow: auto;

      @include for-size(desk-1440) {
        padding: 14px;
      }

      .question-block {
        padding: 12px;
        border-radius: 5px;
        background-color: #f2f8ff;
        border: 1px dashed $border-color;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        p {
          @extend %label;
          margin: 10px 0 0;
          text-transform: unset;

          span {
            color: $primary-color;
            font-size: inherit;
          }
        }

        ul {
          margin: 10px 0 0;
          list-style-type: disc;

          li {
            &:not(:last-child) {
              margin-bottom: 6px;
            }

            &::marker {
              color: #999;
              font-size: 16px;
            }

            p {
              margin: 0;
            }
          }
        }

        .question-title-block {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .question-title {
            display: inline-flex;
            align-items: center;
            background-color: #fff;
            padding: 4px 8px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
          }

          svg,
          path {
            fill: $primary-color;
            height: 12px;
            width: 12px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            color: $text-black;
            display: inline-block;
            margin-left: 6px;
            text-transform: capitalize;
          }

          .delete-btn {
            @extend %button-fill-icon;
            background-color: $text-danger;
            width: 18px;
            height: 18px;
            padding: 0;
            visibility: hidden;

            svg,
            path {
              margin-right: 0;
              width: 9px;
              height: 9px;
              fill: #fff;
            }

            &:hover,
            &:focus {
              border-color: $text-danger;

              svg,
              path {
                fill: $text-danger;
              }
            }
          }
        }

        &:hover {
          border: 1px dashed $primary-color;

          .question-title-block {
            .question-title {
              background-color: $primary-color;
              border: 1px solid transparent;

              svg,
              path {
                fill: #fff;
              }

              span {
                color: #fff;
              }
            }

            .delete-btn {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.tabs-card {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.1);

  .tabs-header {
    background-color: #f2f8ff;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;

    h5 {
      margin-bottom: 0;
      text-transform: unset;
      font-weight: 700;
      font-size: 16px;
      color: $text-black;
    }

    .help-tooltip {
      .table-tooltip {
        background: #fff;
        border: 1px solid #d9d9d9 !important;
        border-radius: 5px;
        max-width: 340px;
        width: 340px;
        position: absolute;
        z-index: 10;
        top: 32px;
        right: 0;
        left: -22px;
        padding: 14px;
        display: none;
        box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);

        @include for-size(ipad-991) {
          top: 32px;
          right: -7px;
          left: auto;
        }

        @include for-size(phone-520) {
          width: 260px;
        }

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 9px solid #d9d9d9;
          position: absolute;
          left: 19px;
          top: -9px;
          rotate: 180deg;

          @include for-size(ipad-991) {
            right: 5px;
            left: auto;
          }
        }

        &::after {
          content: "";
          width: 0;
          height: 0;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 8px solid #fff;
          position: absolute;
          left: 20px;
          top: -8px;
          rotate: 180deg;

          @include for-size(ipad-991) {
            right: 6px;
            left: auto;
          }
        }

        > p {
          font-size: 12px;
          font-weight: 400;
          color: $text-black;
          white-space: normal;
          word-wrap: break-word;
          margin-bottom: 0;
        }
      }

      &:hover {
        .table-tooltip {
          display: block;
        }
      }
    }
  }

  .tabs-body {
    padding: 16px;

    @include for-size(desk-1440) {
      padding: 14px;
    }

    .import-tabs-list {
      .nav-item {
        &:not(:last-child) {
          margin: 0 0 16px;
        }

        .nav-link {
          font-weight: 600;
          text-transform: capitalize;
          font-size: 14px;
          color: $text-black;
          padding: 12px 16px;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          width: 100%;
          text-align: left;

          @include for-size(desk-1440) {
            padding: 8px 12px;
          }

          svg,
          path {
            margin-right: 10px;
            width: 18px;

            @include for-size(desk-1440) {
              margin-right: 8px;
              width: 16px;
            }
          }

          &:hover {
            border: 1px solid $primary-color;
            background-color: #f2f8ff;

            svg,
            path {
              fill: $primary-color;
            }
          }

          &.active {
            background-color: $primary-color;
            color: #fff;
            border: 1px solid transparent;

            svg,
            path {
              fill: #fff;
            }
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .user-name-badge {
      span {
        font-weight: 700;
        font-size: 12px;
        background-color: #fff;
        color: $primary-color;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $primary-color;
        margin: 0 auto;
      }
    }

    .fieldrep-surevy-checkbox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
    }

    .tab-badge {
      font-size: 10px;
      font-weight: 700;
      text-transform: capitalize;
      padding: 3px 8px;
      border-radius: 3px;
      display: inline-block;
      white-space: nowrap;

      &.completed {
        color: #3bb537;
        background-color: #3bb53724;
        border: 1px solid #3bb537;
      }

      &.not-approved {
        color: #ee4549;
        background-color: #ee45491a;
        border: 1px solid #ee4549;
      }

      &.pending {
        color: #6c7688;
        background-color: #6c76881a;
        border: 1px solid #6c7688;
      }

      &.hold {
        color: #a32499;
        background-color: #a324991a;
        border: 1px solid #a32499;
      }
    }

    .survey-fieldrep-data {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;

      .fieldrep-content {
        small {
          font-size: 12px;
          font-weight: 600;
          color: $text-gray;
          display: inline-block;
          margin-bottom: 0;
        }

        h6 {
          @extend %sub-title;
          text-transform: capitalize;
          font-weight: 700;
        }

        p {
          @extend %content;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .applicant-tabs-list {
      .nav-item {
        margin-right: 0;

        &:first-child {
          border-top: 1px solid #e0e0e0;
        }

        &:not(:last-child) {
          .nav-link {
            border-bottom: 1px solid $border-color;
          }
        }

        .nav-link {
          width: 100%;
          padding: 16px;

          @include for-size(desk-1440) {
            padding: 14px;
          }

          &::after {
            display: none;
          }

          &.active {
            background-color: #ebf4ff;

            .survey-fieldrep-data {
              .fieldrep-content {
                h6 {
                  color: $primary-color;
                }
              }

              .user-name-badge {
                span {
                  background-color: $primary-color;
                  color: #fff;
                  border: 1px solid $primary-color;
                }
              }
            }
          }
        }
      }
    }

    .survey-checkbox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      position: relative;

      &.unread {
        &::after {
          content: "";
          height: 28px;
          width: 3px;
          background-color: $primary-color;
          position: absolute;
          left: -16px;
        }
      }
    }
  }
}

.applicant-tab-content {
  .form-block {
    padding: 16px;

    .nice-select-form {
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      width: 400px;

      @include for-size(desk-1440) {
        height: 36px;
        line-height: 36px;
        padding: 0 13px;
      }

      @include for-size(tablet-767) {
        width: 100%;
      }

      .current {
        display: inline-block;
        margin-right: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }

    .nice-select,
    .form-select {
      &:focus {
        background-color: #fff !important;
      }
    }

    .form-select {
      background-image: url("../images/form-drop-arrow.svg");
      background-size: 11px;
      background-position: right 15px center;
    }

    ::placeholder {
      color: $placeholder;
      font-weight: 400;
      font-size: 14px;
    }

    .submit-btn {
      @extend %button-primary;
      margin-top: 20px;

      @include for-size(desk-1440) {
        padding: 7px 14px;
        font-size: 13px;
        line-height: 19px;
      }
    }
  }

  .tab-pane {
    .applicant-wrapper {
      .applicant-data-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px;
        flex-wrap: wrap;
        gap: 16px;

        @include for-size(desk-1440) {
          padding: 14px;
        }

        @include for-size(tablet-767) {
          flex-direction: column;
          align-items: flex-start;
        }

        .applicant-data {
          flex: 0 0 45%;

          @include for-size(tablet-767) {
            flex: 1;
            width: 100%;
          }

          small {
            font-size: 12px;
            font-weight: 400;
            color: #8d8d8d;
            margin-bottom: 4px;
            display: inline-block;
          }

          h6 {
            font-size: 16px;
            font-weight: 400;
            color: #333;
            margin-bottom: 0;
            line-height: 21px;

            @include for-size(desk-1440) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .table-action-btn {
    svg {
      height: 14px !important;
      width: 14px !important;
    }
  }
}

.react-datepicker-wrapper > * .react-datepicker-wrapper {
  font-size: 16px !important;
}

.table-no-border {
  thead {
    display: none;
  }

  td {
    padding: 0 !important;
    border: 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .assign-survey-name {
    border-bottom: 0 !important;
  }

  .checkbox {
    margin-right: 16px !important;
  }
}

/*Shimmer CSS */
.shimmer-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.shimmer-card {
  padding: 10px;
  margin: 10px;
  border-color: #d3d5df;
  // box-shadow: 0px 0px 7px #dadce699;
  border: 1px solid #eee;
  border-radius: 5px;
  flex: 1;
}

.shimmer-title {
  width: 60%;
  margin-top: 10px;
}

.shimmer-tags {
  width: 80%;
  margin-top: 4px;
}

.shimmer-details {
  width: 100%;
  margin-top: 18px;
}

.shimmer-container .shimmer-img {
  height: 144px;
  width: 100%;
}

.shimmer-container .shimmer-img-circle {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}

/*Animation to Shimmer Component Cards */
.stroke {
  height: 15px;
  background: #777;
}

.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }

  to {
    background-position: 1000px 0;
  }
}

/*Avatar group */
.avatar-grp {
  display: flex;

  .avatar {
    border: 2px solid #e0e0e0;
    border-radius: 50%;
    overflow: hidden;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -8px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.more {
      border-color: #007aff;
      background-color: #fff;
      border-width: 1px;
      color: #007aff;
      font-size: 12px;
      font-weight: 700;
      outline: 1px solid #fff;

      &:hover {
        cursor: pointer;
        background-color: #007aff;
        color: #fff;
      }
    }
  }
}

.avatar-grp-tooltip {
  .avatar-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 230px;
    overflow: auto;

    .avatar-list-item {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        overflow: hidden;
        width: 26px;
        height: auto;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        color: #333;
      }

      .avatar {
        border: 2px solid #e0e0e0;
        border-radius: 50%;
        overflow: hidden;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.more {
          border-color: #007aff;
          background-color: #fff;
          border-width: 1px;
          color: #007aff;
          font-size: 12px;
          font-weight: 700;
          outline: 1px solid #fff;

          &:hover {
            cursor: pointer;
            background-color: #007aff;
            color: #fff;
          }
        }
      }
    }
  }
}

.avatar-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.avatar-list::-webkit-scrollbar-thumb {
  border-radius: 16px;
  // -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}

.avatar-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eeeeee;
}

// Notification UI
.notification-item {
  .notification-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notification-data {
    flex: 1 0 auto;
    width: calc(100% - 56px);

    .notification-content {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      background-color: #fafafa;
      margin: 12px 0;
      padding: 12px 15px;

      .notification-title {
        font-weight: 700;
        font-size: 14px;
        color: #333333;
        margin-bottom: 5px;
      }

      .notification-desc {
        color: #7a7a7a;
      }
    }

    .notification-date {
      font-size: 12px;
      color: #7a7a7a;
    }
  }

  &.unread {
    .notification-icon {
      background-color: rgba(0, 122, 255, 0.05);

      svg path {
        fill: #007aff;
      }
    }

    .notification-content {
      background-color: rgba(0, 122, 255, 0.05);
    }
  }
}
