@import "mixins";
@import "buttons";
@import "variables";

body {
  margin: 0;
  padding: 0;
  font-family: $nunito;
}

.admin-wrapper {
  background-color: #f8f8f8;

  header {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.1);
    padding: 10px;
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 250px);
    z-index: 10;

    &.active {
      width: calc(100% - 65px);

      @include for-size(ipad-1024) {
        width: 100%;
      }
    }

    @include for-size(desk-1440) {
      width: calc(100% - 220px);
    }

    @include for-size(ipad-1024) {
      padding: 7px 12px;
      width: 100%;
      z-index: 101;
    }

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo-toggle-menu {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;

        .mob-logo {
          @media screen and (min-width: 1025px) {
            display: none;
          }

          img {
            width: 40px;
          }
        }
      }

      .logo {
        width: 250px;
        text-align: center;

        img {
          max-height: 62px;
        }
      }

      .toggle-mob {
        cursor: pointer;
        padding-left: 0;

        @include for-size(ipad-991) {
          display: block;
        }

        i {
          cursor: pointer;
          font-size: 17px;
        }
      }

      .search-profile-block {
        .user-profile {
          display: flex;
          align-items: center;
          gap: 16px;

          @include for-size(ipad-1024) {
            margin-left: auto;
            gap: 16px;
            order: -1;
            margin-right: 24px;
          }

          .full-screen-btn {
            border: none;
            outline: none;
            padding: 0;
            margin: 0;
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            color: #1a1a1a;
            position: relative;

            span {
              width: 16px;
              height: 16px;
              background-color: $primary-color;
              display: inline-flex;
              color: #fff;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              font-size: 10px;
              font-weight: 600;
              line-height: 10px;
              position: absolute;
              left: 5px;
              top: -4px;
              border: 1.5px solid #fff;
            }
          }

          .dropdown {
            .dropdown-toggle {
              border: none !important;

              &.show {
                border: none !important;
              }

              .user-dropdown-content {
                display: flex;
                align-items: center;
                gap: 8px;

                .user-name {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background-color: $primary-color;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 12px;
                  font-weight: 500;
                  display: inline-block;
                }

                .user-img {
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  border: 1px solid #e0e0e0;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }

                .user-title {
                  text-align: left;

                  @include for-size(ipad-1024) {
                    display: none;
                  }

                  h6 {
                    color: $text-black;
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: capitalize;
                    margin-bottom: 0;

                    @include for-size(desk-1440) {
                      font-size: 14px;
                    }
                  }

                  small {
                    font-size: 10px;
                    font-weight: 400;
                    color: #898989;
                    display: inline-block;
                    margin: 0;
                    text-transform: uppercase;
                  }
                }
              }

              &::after {
                position: absolute;
                right: -9px;
                top: 13px;
                border: none;
                background-image: url("../images/arrow-down.svg");
                background-size: 10px;
                width: 10px;
                height: 16px;
                background-repeat: no-repeat;
                background-position: center;

                @include for-size(ipad-1024) {
                  background-position: center;
                }
              }
            }

            .dropdown-menu {
              background: #ffffff;
              border: 1px solid #e0e0e0;
              box-shadow: 0px 0px 10px rgba(31, 31, 31, 0.15);
              padding: 5px;

              @include for-size(ipad-1024) {
                left: -19px !important;
                top: 6px !important;
              }

              @include for-size(phone-575) {
                margin-left: 48px !important;
              }

              li {
                a {
                  display: inline-flex;
                  align-items: center;
                  border-radius: 5px;
                  font-size: 14px;
                  font-weight: 600;
                  padding: 8px 12px;
                  border: none;
                  transition: all 300ms ease;
                  color: #1a1a1a;
                  text-transform: capitalize;
                  border: 1px solid transparent;

                  svg {
                    fill: $text-black;
                    margin-right: 8px;
                    width: 16px;
                    height: 16px;
                  }

                  &:hover {
                    background-color: #007aff15;
                    color: $text-black;
                    transition: all 300ms ease;
                    border: 1px solid $primary-color;

                    svg,
                    path {
                      fill: $primary-color;
                    }
                  }

                  &:focus {
                    background-color: $primary-color;
                    color: #fff;
                    transition: all 300ms ease;
                    outline: none !important;
                    border: 1px solid transparent;

                    svg,
                    path {
                      fill: #fff;
                    }
                  }

                  &.active {
                    background-color: $primary-color;
                    color: #fff;
                    transition: all 300ms ease;
                    border: 1px solid transparent;

                    svg,
                    path {
                      fill: #fff;
                    }
                  }
                }

                &:not(:last-child) {
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  aside {
    position: relative;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.1);
    z-index: 100;

    .navigation {
      position: fixed;
      top: 0;
      width: 250px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(31, 31, 31, 0.1);
      transition: 0.5s;
      overflow-y: auto;
      overflow-x: hidden;
      left: 0;

      @include for-size(desk-1440) {
        width: 220px;
      }

      @include for-size(ipad-1024) {
        top: 51px;
        padding-bottom: 36px;
      }

      ul.sub-menu li a i {
        display: none;
      }

      &.active {
        ul li a i {
          margin-right: 0;
        }

        ul.sub-menu li a {
          padding: 0px 15px;
          // margin: 0;
          width: 80%;
        }

        ul.sub-menu li a i {
          display: block;
        }

        ul.sub-menu li a .submenu-title {
          display: none;
        }

        .menu-seperator-title {
          width: 100%;
          text-align: center;
          @include for-size(ipad-991) {
            text-align: left;
          }
        }

        .logo {
          margin: 12px auto 27px;
          width: 50px;
          overflow: hidden;
        }

        .logo-md {
          visibility: hidden;
        }

        .logo-sm {
          display: flex;
          visibility: visible;
        }

        .logo-md,
        .logo-sm {
          width: 65px;
          transition: all 300ms ease-in;
        }
      }

      .logo-md,
      .logo-sm {
        white-space: nowrap;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.1);
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 250px;
        z-index: 10;
        transition: all 300ms ease-in;
        top: 0;

        @include for-size(ipad-1024) {
          display: none !important;
        }
      }

      .logo-sm {
        visibility: hidden;
      }

      .logo-wrapper {
        white-space: nowrap;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.1);
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        width: 250px;
        z-index: 10;
        transition: all 300ms ease-in;
        top: 0;
        padding: 0 12px;

        @include for-size(desk-1440) {
          width: 220px;
        }

        img {
          @include for-size(desk-1440) {
            height: 35px;
          }

          &:first-child {
            display: none;
          }
        }

        @include for-size(ipad-1024) {
          height: auto;
        }
      }

      @include for-size(ipad-991) {
        left: -250px;
        transition: 0.5s;
        z-index: 1;
      }

      @include for-size(phone-480) {
        transition: 0.5s;
        width: 100%;
        left: -100%;
        z-index: 1000;
      }

      @include for-size(tablet-768) {
        height: 100%;
        top: 51px;
      }

      ul {
        padding-left: 0;
        margin-bottom: 0;
        transition: all 300ms ease;
        width: 90%;
        margin: 72px auto 0;

        @include for-size(ipad-1024) {
          margin: 10px auto 20px;
        }

        li {
          position: relative;
          width: 100%;
          list-style: none;
          border-radius: 8px;
          margin-bottom: 10px;
          transition: all 300ms ease;

          a {
            padding: 2px 0;
            border-radius: 6px;
            transition: all 300ms ease;
            color: #fff !important;
            position: relative;
            display: inline-flex;
            align-items: center;
            border: 1px solid transparent;

            &::after {
              transition: all 500ms ease-in;
              height: 100%;
            }

            &:hover {
              background-color: #007aff15;
              border: 1px solid $primary-color;

              .title {
                color: $text-black;
              }

              .icon {
                svg,
                path {
                  fill: $primary-color;
                }
              }
            }

            &.active {
              background-color: $primary-color;
              border: 1px solid transparent;

              .title {
                color: #fff;
              }

              .icon {
                svg,
                path {
                  fill: #fff;
                }
              }

              &:hover {
                background-color: $primary-color;
                border: 1px solid transparent;

                .title {
                  color: #fff;
                }

                .icon {
                  svg,
                  path {
                    fill: #fff;
                  }
                }
              }
            }
          }

          a {
            position: relative;
            display: block;
            width: 100%;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            color: #222429;

            div {
              display: inline-flex;
              align-items: center;

              .icon {
                position: relative;
                display: block;
                min-width: 50px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;

                @include for-size(desk-1280) {
                  height: 26px;
                  line-height: 26px;
                }

                svg {
                  width: 18px;
                  height: 18px;

                  @include for-size(desk-1280) {
                    width: 16px;
                    height: 16px;
                  }
                }
              }

              .title {
                position: relative;
                display: block;
                height: 34px;
                line-height: 34px;
                text-align: left;
                white-space: nowrap;
                font-family: "Nunito Sans", sans-serif;
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                color: #000000;

                @include for-size(desk-1280) {
                  height: 30px;
                  line-height: 30px;
                }
                @include for-size(desk-1440) {
                  font-size: 12px;
                }
              }
            }

            &:hover {
              background-color: $primary-light;
              border: 1px solid $primary-color;

              div {
                &.title {
                  color: $text-black;
                }
              }
            }

            i {
              display: block;
              margin-left: auto;
              margin-right: 0;
              line-height: 40px;
              margin-right: 5px;
            }
          }

          .sub-menu {
            // display: none;
            top: 0;
            left: 0;
            width: 100%;
            padding: 14px 0 1px;
            margin-top: 0;
            transition: none !important;
            // background-color: #1c4b5b;
            border-radius: 8px;
            color: #fff;

            > li {
              a {
                font-family: $nunito;
                font-size: 15px;
                font-weight: 500;
                text-transform: capitalize;
                color: #fff;
                display: block;
                width: calc(100% - 30px);
                padding: 10px 32px;
                margin: 0 auto 14px;

                &:not(:last-child) {
                  margin-bottom: 14px;
                }

                &:hover {
                  transition: all 300ms ease;
                  background-color: #217892;
                  color: #fff;
                }
              }
            }
          }
        }

        .menu-seperator-title {
          font-size: 12px;
          font-weight: 600;
          color: #7a7a7a;
          text-transform: capitalize;
          margin: 8px 0 12px;
          display: inline-block;

          @include for-size(ipad-1024) {
            margin: 8px 10px 12px;
          }
        }
      }

      &.active {
        width: 65px;
        transition: 0.5s;
        overflow-x: hidden;
        overflow-y: auto;

        @include for-size(ipad-1024) {
          top: 51px;
          width: 250px;
          left: -250px;
        }

        .logo-wrapper {
          width: 65px;

          @include for-size(ipad-1024) {
            height: auto;
          }

          img {
            &:last-child {
              display: none;
            }

            &:first-child {
              display: block;
            }
          }
        }

        .profile {
          display: none;

          @include for-size(ipad-991) {
            display: block;
          }
        }

        ul {
          left: 7px;
          overflow: hidden;
          width: 50px;
          transition: all 300ms ease;

          @include for-size(ipad-1024) {
            width: 90%;
            margin: 15px auto 20px;
          }

          li {
            width: 100%;
            // height: 50px;

            @include for-size(ipad-992) {
              height: auto;
              width: 100%;
            }
          }
        }

        @include for-size(ipad-991) {
          left: 0;
          width: 250px;
          z-index: 5;
          transition: 0.5s;
          overflow-y: scroll;
          top: 51px;
          height: 100%;
        }

        @include for-size(phone-480) {
          transition: 0.5s;
          width: 100%;
          left: 0;
        }
      }

      .contact-block {
        bottom: 0;
        top: auto;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 18px 18px;

        i {
          font-size: 19px;
          color: #fff;
        }

        div {
          margin-left: 15px;

          a {
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            font-family: $nunito;
            text-decoration: none;
            display: block;
          }
        }
      }
    }

    .profile {
      font-family: $nunito;
      text-align: center;
      padding: 20px 18px 0px;
      width: 300px;
      margin: 0 auto;
      // background-image: url('../images/sidebar-bg.png');

      .profile-image {
        > img {
          border: 1px solid #fff;
          border-radius: 50%;
          padding: 3px;
        }

        h5 {
          font-size: 18px;
          font-weight: 700;
          text-transform: capitalize;
          margin-top: 11px;
        }

        p {
          img {
            width: 19px;
            height: 19px;
            object-fit: cover;
            margin-right: 6px;
          }

          margin-top: 5px;
          font-size: 13px;
          font-weight: 300;
        }
      }

      .profile-content {
        @extend %flex-row-between;

        .term,
        .week,
        .attendance {
          h6 {
            font-size: 14px;
            font-weight: 300;
            text-transform: capitalize;
            margin-bottom: 6px;
          }

          span {
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }

      hr {
        color: #fffa;
      }
    }
  }

  main {
    position: absolute;
    width: calc(100% - 250px);
    left: 250px;
    // min-height: 100%;
    height: calc(100vh - 62px);
    transition: 0.5s;
    top: 62px;
    padding: 16px;
    background-color: #f8f8f8;
    overflow: auto;

    @include for-size(desk-1440) {
      width: calc(100% - 220px);
      left: 220px;
    }

    @include for-size(ipad-1024) {
      left: 0;
      width: 100%;
      top: 53px;
      padding: 20px;
    }

    &.active {
      width: calc(100% - 65px);
      left: 65px;

      @include for-size(ipad-1024) {
        left: 0;
        width: 100%;
        top: 53px;
      }

      @include for-size(phone-480) {
        .toggle {
          position: fixed;
          right: 0;
          left: initial;
          color: #f6f5fc;
        }
      }
    }

    .topbar {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      margin: 25px 0 25px;

      .toggle-block {
        display: inline-flex;
        align-items: center;

        .toggle {
          position: relative;
          top: 0;
          width: 30px;
          height: 30px;
          background-color: #e9e9f0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 0;
          border-radius: 5px;
          cursor: pointer;

          i {
            cursor: pointer;
          }

          @include for-size(phone-480) {
            z-index: 0;
          }
        }

        h6 {
          @extend %title;
          margin-left: 12px;
        }

        .welcome-wrapper {
          .welcome-flex {
          }

          .welcome-text {
            h4 {
              font-size: 24px;
              font-weight: 300;
              color: #202020;
              font-family: $nunito;
              margin-bottom: 6px;

              span {
                font-weight: 700;
                color: #2e0249;
                text-transform: capitalize;
              }
            }

            p {
              font-size: 16px;
              font-weight: 300;
              color: #202020;
              font-family: $nunito;
            }
          }
        }
      }

      &.active {
        width: calc(100% - 66px);
        left: 66px;
        transition: 0.5s;

        @include for-size(ipad-992) {
          left: 0;
          width: 100%;
        }

        .toggle {
          @include for-size(ipad-992) {
            position: fixed;
            right: 0;
            left: initial;
            color: #f6f5fc;
            z-index: -5;
          }
        }
      }
    }
  }
}

/* *****************
scrollbar design start
********************/
.navigation ul::-webkit-scrollbar,
.navigation::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.navigation ul::-webkit-scrollbar-thumb,
.navigation::-webkit-scrollbar-thumb {
  border-radius: 16px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: none;
  // background-color: #e0e0e0;
  background-color: transparent;
}

.navigation ul::-webkit-scrollbar-track,
.navigation::-webkit-scrollbar-track {
  border-radius: 10px;
  // background-color: #eee;
  background-color: transparent;
}

/* *****************
  scrollbar design end
  ********************/
